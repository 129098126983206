import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import CircularLoading from "../../../../../../common/components/progress/CircularLoading";
import TableNoDataOverlay from "../../../../../../common/components/table/TableNoDataOverlay";
import useWindowDimensions from "../../../../../../common/hooks/useWindowDimensions";
import {
  useSelectedDataHubLocation,
  useSelectedDataHubTagLocations,
} from "../../../dataHub.variable";
import { useGetLiveViewData } from "../../hooks/useGetLiveViewData";
import DeviceImageListItem from "./DeviceImageListItem";
import { AutocompleteOptionType } from "../../../../../../common/models/autocomplete";

const DevicesImageList = (): JSX.Element => {
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const locationVariable = useSelectedDataHubLocation();
  const tagLocationVariable = useSelectedDataHubTagLocations();

  const { devices, loading } = useGetLiveViewData();

  if (loading) return <CircularLoading />;

  if (!devices?.length) return <TableNoDataOverlay />;

  if (
    !locationVariable &&
    tagLocationVariable &&
    tagLocationVariable.length === 0
  ) {
    return <TableNoDataOverlay />;
  }

  let cards = [...devices];

  if (tagLocationVariable?.length) {
    cards = devices?.filter(item => {
      const nameOfLocation = item.locationId;

      const locations = tagLocationVariable.map(
        (tag: AutocompleteOptionType) => {
          if (nameOfLocation.includes("#L#")) {
            return tag?.value?.toLowerCase() ?? "";
          }

          return tag?.title?.toLowerCase() ?? "";
        }
      );

      const condition = locations.includes(nameOfLocation.toLowerCase());

      return condition;
    });
  }

  const cardWidth = width < theme.breakpoints.values.xxl ? "294px" : "386px";

  return (
    <Box
      sx={{
        display: "grid",
        gap: "1.25em",
        gridTemplateColumns: `repeat(auto-fill, minmax(min(${cardWidth}, 100%), 1fr))`,
      }}
      component="div"
    >
      {!loading &&
        cards.map((item): JSX.Element => {
          return <DeviceImageListItem key={item.id} device={item} />;
        })}
    </Box>
  );
};

export default DevicesImageList;
