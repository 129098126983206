import { useEffect, useMemo, useState } from "react";

import { ApolloQueryResult, NetworkStatus, useLazyQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";

import {
  Device,
  GetDevicesQuery,
  GetDevicesQueryVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_DEVICES } from "../../../../../common/operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../../../common/variables/common";
import { errorNotification } from "../../../../../common/variables/notification";
import { useSelectedDataHubLocation } from "../../dataHub.variable";

export const useGetLiveViewData = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const locationVariable = useSelectedDataHubLocation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCustomerId) {
      setLoading(true);

      fetchDevices(selectedCustomerId, locationVariable?.value);
    }
  }, [selectedCustomerId, locationVariable?.value]);

  const [getDevices, { data, fetchMore, networkStatus }] = useLazyQuery<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >(GET_DEVICES, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const fetchDevices = async (
    customerId: string,
    locationId?: string,
    nextToken?: string
  ) => {
    try {
      let nextPageToken = null;

      const response = await getDevices({
        variables: {
          limit: DEFAULT_LIMIT_COUNT,
          customerId,
          locationId,
          nextToken,
        },
      });

      if (response?.data?.getDevices?.nextToken) {
        nextPageToken = response?.data?.getDevices?.nextToken;

        // Fetch more results if nextToken is available
        while (nextPageToken) {
          const fetchMoreResults: ApolloQueryResult<GetDevicesQuery> =
            await fetchMore({
              variables: {
                limit: DEFAULT_LIMIT_COUNT,
                customerId: selectedCustomerId,
                locationId,
                nextToken: nextPageToken,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const uniqueDevices = uniqBy(
                  [
                    ...(prev.getDevices?.items ?? []),
                    ...(fetchMoreResult.getDevices?.items ?? []),
                  ],
                  item => item?.id
                );

                return {
                  getDevices: {
                    ...fetchMoreResult.getDevices,
                    items: uniqueDevices,
                  },
                };
              },
            });

          const fetchMoreResultsNextToken: string | null | undefined =
            fetchMoreResults?.data?.getDevices?.nextToken;

          if (fetchMoreResultsNextToken) {
            nextPageToken = fetchMoreResultsNextToken;
          } else {
            nextPageToken = null;
          }
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(`Error while fetching devices: ${error}`);

      errorNotification();
    }
  };

  const devices: Device[] = useMemo(
    (): Device[] => (data?.getDevices.items as Device[]) || [],
    [data?.getDevices.items]
  );

  console.log("DEVICES", devices);

  return {
    devices,
    loading: loading || networkStatus === NetworkStatus.fetchMore,
  };
};
