import { useRef, useState } from "react";

import axios from "axios";

import { signedUrlActions } from "../../API";
import { S3BucketKeys } from "../../common/utils/s3helper";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import { useGetSignedUrlAction } from "./useGetSignedUrlAction";

interface UseAddFile {
  addFile: (uploadVideoPath: string, file: File) => Promise<void>;
  waitForAllUploads: () => Promise<void>;
  error: Error | null;
  loading: boolean;
}

const useAddFile = (): UseAddFile => {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const uploadPromises = useRef<Promise<any>[]>([]);

  const { fetchSignedUrlAction } = useGetSignedUrlAction();

  const addFile = async (uploadVideoPath: string, fileToUpload: File) => {
    setIsLoading(true);

    setError(null);

    const response = await fetchSignedUrlAction({
      contentType: fileToUpload.type,
      operation: signedUrlActions.putObject,
      objectKey: uploadVideoPath,
      bucketKey: S3BucketKeys.LEAK_FINDER,
    });

    if (response && fileToUpload) {
      try {
        const uploadPromise = axios.put(response ?? "", fileToUpload, {
          headers: {
            "Content-Type": fileToUpload?.type,
          },
        });

        uploadPromises.current.push(uploadPromise);
      } catch (err) {
        const errorToSet =
          err instanceof Error ? err : new Error("Unknown error occurred");

        setError(errorToSet);

        errorNotification(errorToSet.message);

        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const waitForAllUploads = async (): Promise<void> => {
    setIsLoading(true);

    const results = await Promise.allSettled(uploadPromises.current);
    const errors = results.filter(result => result.status === "rejected");

    if (errors.length > 0) {
      const errorMessage = "Some uploads failed.";

      setError(new Error(errorMessage));

      errorNotification(errorMessage);
    } else {
      successNotification("All files uploaded successfully.");
    }

    uploadPromises.current = [];

    setIsLoading(false);
  };

  return { addFile, waitForAllUploads, error, loading: isLoading };
};

export default useAddFile;
