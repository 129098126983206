import { useMemo } from "react";

import { GridRowsProp } from "@mui/x-data-grid";
import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  IParsedSmokeEvent,
  ISmokeEventsTableRow,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";
import { getRowIndex } from "../../common/events.helpers";

export const useGetSmokeEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<ISmokeEventsTableRow> => {
    const mappedData: ISmokeEventsTableRow[] =
      parsedResult?.map((item: IParsedSmokeEvent, index: number) => ({
        rowIndex: getRowIndex(paginationModel, index),
        rowId: item?.id ?? "",
        location: item?.location_id,
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        keyFrame: item?.keyFrame,
        mediaOutput: item?.mediaOutput,
        signedUrlKeyFrame: item?.signedUrlKeyFrame,
        signedUrlMediaOutput: item?.signedUrlMediaOutput,
        cameraId: item?.camera_id,
        confidence: item?.confidence,
      })) ?? [];

    return mappedData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
