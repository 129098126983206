import { memo } from "react";

import CardMediaVideoPlayerWithSpeedControls from "../../../pages/media-viewer/components/player/CardMediaVideoPlayerWithSpeedControls";
import { IS3MediaFile } from "./IS3MediaFileProps";

interface IS3VideoProps extends IS3MediaFile {
  controls?: boolean;
}

const S3VideoComponent = ({ s3Key, controls }: IS3VideoProps): JSX.Element => {
  return (
    <div>
      {!s3Key && <div>No Video Available</div>}
      {s3Key && (
        <CardMediaVideoPlayerWithSpeedControls
          sx={{
            maxHeight: "calc(100vh - 64px)",
          }}
          controls={controls}
          src={s3Key}
          autoPlay
        />
      )}
    </div>
  );
};

const S3Video = memo(
  S3VideoComponent,
  (prevProps, nextProps): boolean => prevProps.s3Key === nextProps.s3Key
);

export default S3Video;
