import { FC, memo, useState } from "react";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import Highlighter from "react-highlight-words";

import { Group, signedUrlActions } from "../../API";
import RouterLink from "../../common/components/RouterLink";
import EditIconButton from "../../common/components/icons/EditIconButton";
import FullPageLoader from "../../common/components/item/FullPageLoader";
import { RouteEnum } from "../../common/models/enums";
import { S3BucketKeys } from "../../common/utils/s3helper";
import { useGetSignedUrlAction } from "../hooks/useGetSignedUrlAction";
import { useGetVideosTableColumns } from "../hooks/useGetVideosTableColumns";
import {
  LeakFinderTableRow,
  useGetVideosTableRows,
} from "../hooks/useGetVideosTableRows";
import { VideosTableSelectionDispatch } from "../store/useVideosTableSelectionReducer";
import { useClientUploadsSearchVariable } from "../variables/clientUploads";
import DeleteClientGroupContainer from "./DeleteClientGroupContainer";
import VideosTable from "./VideosTable";

interface IGroupCardProps {
  group: Group;
  groupIndex: number;
  clientId: string;
  clientName: string;
  selectedRows: LeakFinderTableRow[];
  dispatch: VideosTableSelectionDispatch;
  deleteGroup: (groupId: string, clientId: string) => void;
}

const GroupCardComponent: FC<IGroupCardProps> = ({
  group,
  clientId,
  clientName,
  selectedRows,
  groupIndex,
  dispatch,
  deleteGroup,
}) => {
  const clientUploadsSearchValue = useClientUploadsSearchVariable();

  const { fetchSignedUrlAction } = useGetSignedUrlAction();

  const [selectedUrlForStream, setSelectedUrlForStream] = useState<
    string | null
  >(null);

  const onCloseToggle = () => setSelectedUrlForStream(null);

  const setVideoUrl = async (url: string) => {
    const signedUrl = await fetchSignedUrlAction({
      objectKey: url,
      operation: signedUrlActions.getObject,
      bucketKey: S3BucketKeys.LEAK_FINDER,
    });

    setSelectedUrlForStream(signedUrl);
  };

  const { rows, loading } = useGetVideosTableRows(group.id);

  const { columns } = useGetVideosTableColumns(
    async (url: string) => {
      await setVideoUrl(url);
    },
    deleteGroup,
    rows.length
  );

  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(prev => !prev);
  };

  const queryParams = new URLSearchParams({
    groupId: group.id,
    clientId: group.clientId,
  }).toString();

  const editGroupLink = `${RouteEnum.LeakFinderClientUploadsEditGroup}?${queryParams}`;

  const date = group.createdAt ? moment(group.createdAt) : "";

  const formattedDate = date ? date.format("MM/DD/YYYY") : date;

  if (loading && groupIndex === 0) {
    return <FullPageLoader />;
  }

  if (rows.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: "0.75em 1.5em",
        borderRadius: "12px",
        height: expanded ? "100%" : "64px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme => theme.palette.otherBackground.main,
        "&:hover": {
          backgroundColor: theme => theme.palette.divider,

          ".length-and-date-label": {
            color: theme => theme.palette.text.secondary,
          },
        },
        "&:hover .group-card-actions": {
          visibility: "visible",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!expanded && (
          <KeyboardArrowRightRoundedIcon
            onClick={toggleAccordion}
            sx={{ cursor: "pointer" }}
          />
        )}
        {expanded && (
          <KeyboardArrowUpRoundedIcon
            onClick={toggleAccordion}
            sx={{ cursor: "pointer" }}
          />
        )}
        <Box
          onClick={toggleAccordion}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: "0.25em",
          }}
        >
          <Typography variant="body2Bold">
            <Highlighter
              searchWords={[clientUploadsSearchValue]}
              autoEscape
              textToHighlight={group.groupName}
            />
          </Typography>
          <Typography
            variant="inputLabel"
            color={expanded ? "text.secondary" : "textTertiary.main"}
            className="length-and-date-label"
          >
            {`${rows.length} video${rows.length > 1 ? "s" : ""} ${
              formattedDate ? `| ${formattedDate}` : `${formattedDate}`
            }`}
          </Typography>
        </Box>
        <Box
          sx={{
            visibility: expanded ? "visible" : "hidden",
            ml: "auto",
            cursor: "pointer",
            pointerEvents: "auto",
            height: "32px",
          }}
          className="group-card-actions"
        >
          <RouterLink sx={{ marginRight: "0.5em" }} to={editGroupLink}>
            <Tooltip TransitionComponent={Zoom} title={"Edit"}>
              <span>
                <EditIconButton />
              </span>
            </Tooltip>
          </RouterLink>

          <DeleteClientGroupContainer
            deleteGroup={deleteGroup}
            dispatch={dispatch}
            group={group}
          />
        </Box>
      </Box>

      {expanded && !loading && (
        <VideosTable
          rows={rows}
          columns={columns}
          loading={loading}
          selectedUrlForStream={selectedUrlForStream}
          onDialogClose={onCloseToggle}
          clientId={clientId}
          clientName={clientName}
          groupId={group.id}
          groupName={group.groupName}
          dispatch={dispatch}
          selectedRows={selectedRows}
        />
      )}
    </Box>
  );
};

const GroupCard = memo(GroupCardComponent, (prevProps, nextProps) => {
  return (
    prevProps.clientId === nextProps.clientId &&
    prevProps.clientName === nextProps.clientName &&
    prevProps.group.id === nextProps.group.id &&
    prevProps.groupIndex === nextProps.groupIndex &&
    prevProps.selectedRows.length === nextProps.selectedRows.length
  );
});

export default GroupCard;
