/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const listenToAddZone = /* GraphQL */ `subscription ListenToAddZone($customerId: String) {
  listenToAddZone(customerId: $customerId) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToAddZoneSubscriptionVariables,
  APITypes.ListenToAddZoneSubscription
>;
export const listenToDeleteZone = /* GraphQL */ `subscription ListenToDeleteZone($id: String) {
  listenToDeleteZone(id: $id) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToDeleteZoneSubscriptionVariables,
  APITypes.ListenToDeleteZoneSubscription
>;
export const listenToDeviceData = /* GraphQL */ `subscription ListenToDeviceData($customerId: String!) {
  listenToDeviceData(customerId: $customerId) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToDeviceDataSubscriptionVariables,
  APITypes.ListenToDeviceDataSubscription
>;
export const listenToDeviceHealthData = /* GraphQL */ `subscription ListenToDeviceHealthData($customerId: String!) {
  listenToDeviceHealthData(customerId: $customerId) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToDeviceHealthDataSubscriptionVariables,
  APITypes.ListenToDeviceHealthDataSubscription
>;
export const listenToEdgeClientMessage = /* GraphQL */ `subscription ListenToEdgeClientMessage($id: String) {
  listenToEdgeClientMessage(id: $id) {
    id
    payload
    payloadType
    statusCode
    timestamp
    topic
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToEdgeClientMessageSubscriptionVariables,
  APITypes.ListenToEdgeClientMessageSubscription
>;
export const listenToHumanValidationEvents = /* GraphQL */ `subscription ListenToHumanValidationEvents {
  listenToHumanValidationEvents {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToHumanValidationEventsSubscriptionVariables,
  APITypes.ListenToHumanValidationEventsSubscription
>;
export const listenToNodeFiles = /* GraphQL */ `subscription ListenToNodeFiles($nodeId: String!) {
  listenToNodeFiles(nodeId: $nodeId) {
    downloadURL
    file
    internalNodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToNodeFilesSubscriptionVariables,
  APITypes.ListenToNodeFilesSubscription
>;
export const listenToNodeMessages = /* GraphQL */ `subscription ListenToNodeMessages($nodeId: String!) {
  listenToNodeMessages(nodeId: $nodeId) {
    internalNodeId
    message
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToNodeMessagesSubscriptionVariables,
  APITypes.ListenToNodeMessagesSubscription
>;
export const listenToNodeOnlineStatus = /* GraphQL */ `subscription ListenToNodeOnlineStatus($customerId: String!) {
  listenToNodeOnlineStatus(customerId: $customerId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToNodeOnlineStatusSubscriptionVariables,
  APITypes.ListenToNodeOnlineStatusSubscription
>;
export const listenToServiceHealthData = /* GraphQL */ `subscription ListenToServiceHealthData($customerId: String!) {
  listenToServiceHealthData(customerId: $customerId) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToServiceHealthDataSubscriptionVariables,
  APITypes.ListenToServiceHealthDataSubscription
>;
export const listenToUpdateZone = /* GraphQL */ `subscription ListenToUpdateZone($id: String) {
  listenToUpdateZone(id: $id) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ListenToUpdateZoneSubscriptionVariables,
  APITypes.ListenToUpdateZoneSubscription
>;
