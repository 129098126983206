export const allowedFileTypes = {
  images: ["image/*", ".jpg", ".jpeg", ".png", ".gif", ".svg", ".webp"],
  videos: [
    "video/*",
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".3gp",
    ".mkv",
    ".webm",
  ],
};

export const extractFileNameExt = (file: string) => {
  if (!file) {
    return null;
  }

  return "." + file.split(".").pop();
};

export const getImagePreview = (blob: Blob) => {
  return blob && URL.createObjectURL(blob);
};

export const isImageFile = (fileName: string) => {
  if (!fileName) {
    return false;
  }

  const extension = extractFileNameExt(fileName) ?? "";

  return allowedFileTypes.images.includes(extension);
};

export const isVideoFile = (fileName: string) => {
  if (!fileName) {
    return false;
  }

  const extension = extractFileNameExt(fileName) ?? "";

  return allowedFileTypes.videos.includes(extension);
};

export const getFileExtensionFromSignedUrl = (signedUrl: string) => {
  if (!signedUrl) {
    return "";
  }

  // Extract the key portion from the URL
  const key = signedUrl.split(".com/")[1].split("?")[0];

  // Get the file extension
  const extension = key.substring(key.lastIndexOf("."));

  return extension;
};
