import { useMemo } from "react";

import { GridRowsProp } from "@mui/x-data-grid";
import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import { getRowIndex } from "../../common/events.helpers";
import {
  IGasLeakTableRow,
  IParsedGasLeakEvent,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";

export const useGetGasLeakEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<IGasLeakTableRow> => {
    const mappedData: IGasLeakTableRow[] =
      parsedResult?.map((item: IParsedGasLeakEvent, index: number) => ({
        rowIndex: getRowIndex(paginationModel, index),
        rowId: item?.id ?? "",
        location: item?.location_id,
        zone: item?.zone_id,
        timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
        rate: item?.rate_mcf,
        cameraId: item?.camera_id ?? "",
        volume: item?.leak_volume,
        mediaOutput: item?.mediaOutput,
        keyFrame: item?.keyFrame,
        signedUrlKeyFrame: item?.signedUrlKeyFrame,
        signedUrlMediaOutput: item?.signedUrlMediaOutput,
      })) ?? [];

    return mappedData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
