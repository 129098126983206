import { useMutation } from "@apollo/client";

import type {
  DeleteFolderFromS3Mutation,
  DeleteFolderFromS3MutationVariables,
} from "../../API";
import { DELETE_FOLDER_FROM_S3 } from "../operations/mutations";

export const useDeleteFolderFromS3 = () => {
  const [removeFolderFromS3, { data, loading, error }] = useMutation<
    DeleteFolderFromS3Mutation,
    DeleteFolderFromS3MutationVariables
  >(DELETE_FOLDER_FROM_S3);

  const deleteFolderFromS3 = async ({
    folderPath,
  }: DeleteFolderFromS3MutationVariables) => {
    return await removeFolderFromS3({
      variables: {
        folderPath,
      },
    });
  };

  return { deleteFolderFromS3, data, loading, error };
};
