/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addAlertEmailNotification = /* GraphQL */ `mutation AddAlertEmailNotification($input: addAlertEmailNotificationInput) {
  addAlertEmailNotification(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAlertEmailNotificationMutationVariables,
  APITypes.AddAlertEmailNotificationMutation
>;
export const addAlertSubscription = /* GraphQL */ `mutation AddAlertSubscription($input: AddAlertSubscriptionInput!) {
  addAlertSubscription(input: $input) {
    email
    entity
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAlertSubscriptionMutationVariables,
  APITypes.AddAlertSubscriptionMutation
>;
export const addAlertWildCardSubscription = /* GraphQL */ `mutation AddAlertWildCardSubscription(
  $input: AddAlertWildCardSubscriptionInput!
) {
  addAlertWildCardSubscription(input: $input) {
    email
    entity
    wildCard
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAlertWildCardSubscriptionMutationVariables,
  APITypes.AddAlertWildCardSubscriptionMutation
>;
export const addAnnotation = /* GraphQL */ `mutation AddAnnotation($input: AnnotationInput!) {
  addAnnotation(input: $input) {
    annotateTanks
    annotationType
    bottomHidden
    deviceId
    equipmentType
    id
    name
    polygon
    serviceId
    tankHeight
    tankNumber
    zoneId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddAnnotationMutationVariables,
  APITypes.AddAnnotationMutation
>;
export const addCategory = /* GraphQL */ `mutation AddCategory($input: AddCategoryInput!) {
  addCategory(input: $input) {
    categoryName
    id
    isAlertEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCategoryMutationVariables,
  APITypes.AddCategoryMutation
>;
export const addClient = /* GraphQL */ `mutation AddClient($input: AddClientInput!) {
  addClient(input: $input) {
    clientName
    customerId
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddClientMutationVariables,
  APITypes.AddClientMutation
>;
export const addClientGroup = /* GraphQL */ `mutation AddClientGroup($input: AddClientGroupInput) {
  addClientGroup(input: $input) {
    clientId
    createdAt
    customerId
    groupName
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddClientGroupMutationVariables,
  APITypes.AddClientGroupMutation
>;
export const addCustomerDTObject = /* GraphQL */ `mutation AddCustomerDTObject($input: DTObjectInput!) {
  addCustomerDTObject(input: $input) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddCustomerDTObjectMutationVariables,
  APITypes.AddCustomerDTObjectMutation
>;
export const addDTObject = /* GraphQL */ `mutation AddDTObject($input: DTObjectInput!) {
  addDTObject(input: $input) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDTObjectMutationVariables,
  APITypes.AddDTObjectMutation
>;
export const addDTProject = /* GraphQL */ `mutation AddDTProject($input: AddDTProjectInput!) {
  addDTProject(input: $input) {
    configURL
    customerId
    displayName
    id
    kmzLayerURL
    lastModificationDate
    lastModifierUserName
    measurementSystem
    projectId
    withKMZLayer
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDTProjectMutationVariables,
  APITypes.AddDTProjectMutation
>;
export const addDevice = /* GraphQL */ `mutation AddDevice($input: AddDeviceInput!) {
  addDevice(input: $input) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDeviceMutationVariables,
  APITypes.AddDeviceMutation
>;
export const addDeviceMake = /* GraphQL */ `mutation AddDeviceMake($input: AddDeviceMakeInput!) {
  addDeviceMake(input: $input) {
    defaultUserPwd
    deviceTypeShortName
    hasPanTilt
    hasZoom
    id
    model
    modelsToRun
    name
    rtspTemplate
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDeviceMakeMutationVariables,
  APITypes.AddDeviceMakeMutation
>;
export const addDomain = /* GraphQL */ `mutation AddDomain($customerId: String!, $domain: String!) {
  addDomain(customerId: $customerId, domain: $domain) {
    customerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDomainMutationVariables,
  APITypes.AddDomainMutation
>;
export const addLicensePlate = /* GraphQL */ `mutation AddLicensePlate($input: AddLicensePlateInput!) {
  addLicensePlate(input: $input) {
    categoryId
    company
    driverName
    id
    licensePlate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddLicensePlateMutationVariables,
  APITypes.AddLicensePlateMutation
>;
export const addLocation = /* GraphQL */ `mutation AddLocation($input: LocationInput!) {
  addLocation(input: $input) {
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    locationData
    name
    referenceId
    tags {
      associationId
      id
      tagName
      __typename
    }
    timeZone
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddLocationMutationVariables,
  APITypes.AddLocationMutation
>;
export const addNode = /* GraphQL */ `mutation AddNode($input: AddNodeInput!) {
  addNode(input: $input) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddNodeMutationVariables,
  APITypes.AddNodeMutation
>;
export const addService = /* GraphQL */ `mutation AddService($input: AddServiceInput!) {
  addService(input: $input) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddServiceMutationVariables,
  APITypes.AddServiceMutation
>;
export const addTag = /* GraphQL */ `mutation AddTag($input: AddTagInput!) {
  addTag(input: $input) {
    id
    tagName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddTagMutationVariables,
  APITypes.AddTagMutation
>;
export const addZone = /* GraphQL */ `mutation AddZone($input: AddZoneInput!) {
  addZone(input: $input) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddZoneMutationVariables,
  APITypes.AddZoneMutation
>;
export const addZoneThreshold = /* GraphQL */ `mutation AddZoneThreshold($input: AddZoneThresholdInput!) {
  addZoneThreshold(input: $input) {
    id
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddZoneThresholdMutationVariables,
  APITypes.AddZoneThresholdMutation
>;
export const assignFirstRoleToUser = /* GraphQL */ `mutation AssignFirstRoleToUser(
  $customerId: String!
  $groupId: String!
  $roleId: String!
  $userId: String!
  $userPoolId: String
) {
  assignFirstRoleToUser(
    customerId: $customerId
    groupId: $groupId
    roleId: $roleId
    userId: $userId
    userPoolId: $userPoolId
  ) {
    customer_id
    email
    externalId
    firstName
    id
    lastName
    userSettings
    user_role
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AssignFirstRoleToUserMutationVariables,
  APITypes.AssignFirstRoleToUserMutation
>;
export const batchAddCategory = /* GraphQL */ `mutation BatchAddCategory($input: [AddCategoryInput!]!) {
  batchAddCategory(input: $input) {
    categoryName
    id
    isAlertEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddCategoryMutationVariables,
  APITypes.BatchAddCategoryMutation
>;
export const batchAddLicensePlate = /* GraphQL */ `mutation BatchAddLicensePlate($input: [AddLicensePlateInput!]!) {
  batchAddLicensePlate(input: $input) {
    categoryId
    company
    driverName
    id
    licensePlate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddLicensePlateMutationVariables,
  APITypes.BatchAddLicensePlateMutation
>;
export const batchAddTagAssociations = /* GraphQL */ `mutation BatchAddTagAssociations($input: BatchAddTagAssociationsInput) {
  batchAddTagAssociations(input: $input) {
    associationId
    id
    tagName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddTagAssociationsMutationVariables,
  APITypes.BatchAddTagAssociationsMutation
>;
export const batchAddVideos = /* GraphQL */ `mutation BatchAddVideos($input: AddVideosInput) {
  batchAddVideos(input: $input) {
    annotations
    clientId
    customerId
    fov
    geometry
    groupId
    id
    isVideoProcessed
    modelOutputS3Key
    notes
    outputMeasurement
    sourceS3Key
    videoMethod
    videoName
    videoState
    windspeed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchAddVideosMutationVariables,
  APITypes.BatchAddVideosMutation
>;
export const batchDeleteClientGroups = /* GraphQL */ `mutation BatchDeleteClientGroups($input: [BatchDeleteClientGroupsInput!]!) {
  batchDeleteClientGroups(input: $input) {
    clientId
    createdAt
    customerId
    groupName
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchDeleteClientGroupsMutationVariables,
  APITypes.BatchDeleteClientGroupsMutation
>;
export const batchDeleteVideos = /* GraphQL */ `mutation BatchDeleteVideos($input: [BatchDeleteVideosInput!]!) {
  batchDeleteVideos(input: $input) {
    groupId
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchDeleteVideosMutationVariables,
  APITypes.BatchDeleteVideosMutation
>;
export const changeNodeStatus = /* GraphQL */ `mutation ChangeNodeStatus($input: ChangeNodeStatusInput!) {
  changeNodeStatus(input: $input)
}
` as GeneratedMutation<
  APITypes.ChangeNodeStatusMutationVariables,
  APITypes.ChangeNodeStatusMutation
>;
export const createSecretsManagerSecret = /* GraphQL */ `mutation CreateSecretsManagerSecret($input: createSecretsManagerSecretInput!) {
  createSecretsManagerSecret(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateSecretsManagerSecretMutationVariables,
  APITypes.CreateSecretsManagerSecretMutation
>;
export const deactivateNodeOnboardScript = /* GraphQL */ `mutation DeactivateNodeOnboardScript($locationId: String!, $nodeId: String!) {
  deactivateNodeOnboardScript(locationId: $locationId, nodeId: $nodeId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeactivateNodeOnboardScriptMutationVariables,
  APITypes.DeactivateNodeOnboardScriptMutation
>;
export const deleteAlert = /* GraphQL */ `mutation DeleteAlert($input: DeleteAlertInput!) {
  deleteAlert(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlertMutationVariables,
  APITypes.DeleteAlertMutation
>;
export const deleteAllAnnotations = /* GraphQL */ `mutation DeleteAllAnnotations($input: DeleteAllAnnotationsInput!) {
  deleteAllAnnotations(input: $input) {
    id
    zoneId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAllAnnotationsMutationVariables,
  APITypes.DeleteAllAnnotationsMutation
>;
export const deleteAnnotation = /* GraphQL */ `mutation DeleteAnnotation($input: DeleteAnnotationInput!) {
  deleteAnnotation(input: $input) {
    annotateTanks
    annotationType
    bottomHidden
    deviceId
    equipmentType
    id
    name
    polygon
    serviceId
    tankHeight
    tankNumber
    zoneId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAnnotationMutationVariables,
  APITypes.DeleteAnnotationMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory($input: DeleteCategory!) {
  deleteCategory(input: $input) {
    categoryName
    id
    isAlertEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient($input: DeleteClientInput!) {
  deleteClient(input: $input) {
    clientName
    customerId
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const deleteClientGroup = /* GraphQL */ `mutation DeleteClientGroup($input: DeleteClientGroupInput) {
  deleteClientGroup(input: $input) {
    clientId
    createdAt
    customerId
    groupName
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientGroupMutationVariables,
  APITypes.DeleteClientGroupMutation
>;
export const deleteCustomerDTObject = /* GraphQL */ `mutation DeleteCustomerDTObject($input: DeleteDTObjectInput!) {
  deleteCustomerDTObject(input: $input) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomerDTObjectMutationVariables,
  APITypes.DeleteCustomerDTObjectMutation
>;
export const deleteDTProject = /* GraphQL */ `mutation DeleteDTProject($input: DeleteDTProjectInput!) {
  deleteDTProject(input: $input) {
    configURL
    customerId
    displayName
    id
    kmzLayerURL
    lastModificationDate
    lastModifierUserName
    measurementSystem
    projectId
    withKMZLayer
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDTProjectMutationVariables,
  APITypes.DeleteDTProjectMutation
>;
export const deleteDevice = /* GraphQL */ `mutation DeleteDevice($customerId: String!, $deviceId: String!) {
  deleteDevice(customerId: $customerId, deviceId: $deviceId)
}
` as GeneratedMutation<
  APITypes.DeleteDeviceMutationVariables,
  APITypes.DeleteDeviceMutation
>;
export const deleteDeviceMake = /* GraphQL */ `mutation DeleteDeviceMake($id: String!) {
  deleteDeviceMake(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteDeviceMakeMutationVariables,
  APITypes.DeleteDeviceMakeMutation
>;
export const deleteFolderFromS3 = /* GraphQL */ `mutation DeleteFolderFromS3($folderPath: String!) {
  deleteFolderFromS3(folderPath: $folderPath)
}
` as GeneratedMutation<
  APITypes.DeleteFolderFromS3MutationVariables,
  APITypes.DeleteFolderFromS3Mutation
>;
export const deleteLicensePlate = /* GraphQL */ `mutation DeleteLicensePlate($input: DeleteLicensePlate!) {
  deleteLicensePlate(input: $input) {
    categoryId
    company
    driverName
    id
    licensePlate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLicensePlateMutationVariables,
  APITypes.DeleteLicensePlateMutation
>;
export const deleteLocation = /* GraphQL */ `mutation DeleteLocation($customerId: String!, $locationId: String!) {
  deleteLocation(customerId: $customerId, locationId: $locationId)
}
` as GeneratedMutation<
  APITypes.DeleteLocationMutationVariables,
  APITypes.DeleteLocationMutation
>;
export const deleteNode = /* GraphQL */ `mutation DeleteNode($nodeId: String!) {
  deleteNode(nodeId: $nodeId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNodeMutationVariables,
  APITypes.DeleteNodeMutation
>;
export const deleteService = /* GraphQL */ `mutation DeleteService($input: DeleteServiceInput!) {
  deleteService(input: $input) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceMutationVariables,
  APITypes.DeleteServiceMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag($input: DeleteTagInput!) {
  deleteTag(input: $input) {
    id
    tagName
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $customerId: String!
  $userId: String!
  $userPoolId: String!
) {
  deleteUser(
    customerId: $customerId
    userId: $userId
    userPoolId: $userPoolId
  ) {
    customer_id
    email
    externalId
    firstName
    id
    lastName
    userSettings
    user_role
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const deleteVideo = /* GraphQL */ `mutation DeleteVideo($input: DeleteVideoInput!) {
  deleteVideo(input: $input) {
    annotations
    clientId
    customerId
    fov
    geometry
    groupId
    id
    isVideoProcessed
    modelOutputS3Key
    notes
    outputMeasurement
    sourceS3Key
    videoMethod
    videoName
    videoState
    windspeed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVideoMutationVariables,
  APITypes.DeleteVideoMutation
>;
export const deleteZone = /* GraphQL */ `mutation DeleteZone($input: DeleteZoneInput!) {
  deleteZone(input: $input) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZoneMutationVariables,
  APITypes.DeleteZoneMutation
>;
export const deleteZoneThreshold = /* GraphQL */ `mutation DeleteZoneThreshold($id: ID!) {
  deleteZoneThreshold(id: $id) {
    id
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZoneThresholdMutationVariables,
  APITypes.DeleteZoneThresholdMutation
>;
export const edgeClientMessenger = /* GraphQL */ `mutation EdgeClientMessenger($message: EdgeClientMessageInput) {
  edgeClientMessenger(message: $message) {
    id
    payload
    payloadType
    statusCode
    timestamp
    topic
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EdgeClientMessengerMutationVariables,
  APITypes.EdgeClientMessengerMutation
>;
export const overruleHumanValidatedEvents = /* GraphQL */ `mutation OverruleHumanValidatedEvents($input: ValidateEventInput!) {
  overruleHumanValidatedEvents(input: $input) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OverruleHumanValidatedEventsMutationVariables,
  APITypes.OverruleHumanValidatedEventsMutation
>;
export const publishNode = /* GraphQL */ `mutation PublishNode($input: publishNodeInput!) {
  publishNode(input: $input) {
    message
    nodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishNodeMutationVariables,
  APITypes.PublishNodeMutation
>;
export const sendToListenToHumanValidationEvents = /* GraphQL */ `mutation SendToListenToHumanValidationEvents($humanValidationEvent: AWSJSON!) {
  sendToListenToHumanValidationEvents(
    humanValidationEvent: $humanValidationEvent
  ) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendToListenToHumanValidationEventsMutationVariables,
  APITypes.SendToListenToHumanValidationEventsMutation
>;
export const sendToListenToNodeFiles = /* GraphQL */ `mutation SendToListenToNodeFiles($nodeFile: AWSJSON!) {
  sendToListenToNodeFiles(nodeFile: $nodeFile) {
    downloadURL
    file
    internalNodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendToListenToNodeFilesMutationVariables,
  APITypes.SendToListenToNodeFilesMutation
>;
export const sendToListenToNodeMessages = /* GraphQL */ `mutation SendToListenToNodeMessages($nodeMessage: AWSJSON!) {
  sendToListenToNodeMessages(nodeMessage: $nodeMessage) {
    internalNodeId
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendToListenToNodeMessagesMutationVariables,
  APITypes.SendToListenToNodeMessagesMutation
>;
export const setIsRunningServiceStatus = /* GraphQL */ `mutation SetIsRunningServiceStatus($input: SetServiceStatusInput!) {
  setIsRunningServiceStatus(input: $input) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetIsRunningServiceStatusMutationVariables,
  APITypes.SetIsRunningServiceStatusMutation
>;
export const setPendingState = /* GraphQL */ `mutation SetPendingState($customerId: String, $eventId: String) {
  setPendingState(customerId: $customerId, eventId: $eventId) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetPendingStateMutationVariables,
  APITypes.SetPendingStateMutation
>;
export const syncShadow = /* GraphQL */ `mutation SyncShadow($nodeId: String!) {
  syncShadow(nodeId: $nodeId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SyncShadowMutationVariables,
  APITypes.SyncShadowMutation
>;
export const takeEventToAudit = /* GraphQL */ `mutation TakeEventToAudit($customerId: String, $eventId: String) {
  takeEventToAudit(customerId: $customerId, eventId: $eventId) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TakeEventToAuditMutationVariables,
  APITypes.TakeEventToAuditMutation
>;
export const takeEventToValidate = /* GraphQL */ `mutation TakeEventToValidate($customerId: String, $eventId: String) {
  takeEventToValidate(customerId: $customerId, eventId: $eventId) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TakeEventToValidateMutationVariables,
  APITypes.TakeEventToValidateMutation
>;
export const updateAdminEmailAlert = /* GraphQL */ `mutation UpdateAdminEmailAlert($input: UpdateAdminEmailAlertInput!) {
  updateAdminEmailAlert(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminEmailAlertMutationVariables,
  APITypes.UpdateAdminEmailAlertMutation
>;
export const updateAdminSystemAlert = /* GraphQL */ `mutation UpdateAdminSystemAlert($input: UpdateAdminSystemAlertInput!) {
  updateAdminSystemAlert(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminSystemAlertMutationVariables,
  APITypes.UpdateAdminSystemAlertMutation
>;
export const updateAlert = /* GraphQL */ `mutation UpdateAlert($input: UpdateAlertInput!) {
  updateAlert(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlertMutationVariables,
  APITypes.UpdateAlertMutation
>;
export const updateAlertStatus = /* GraphQL */ `mutation UpdateAlertStatus($input: UpdateAlertStatusInput!) {
  updateAlertStatus(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlertStatusMutationVariables,
  APITypes.UpdateAlertStatusMutation
>;
export const updateAnnotation = /* GraphQL */ `mutation UpdateAnnotation($input: AnnotationInput!) {
  updateAnnotation(input: $input) {
    annotateTanks
    annotationType
    bottomHidden
    deviceId
    equipmentType
    id
    name
    polygon
    serviceId
    tankHeight
    tankNumber
    zoneId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAnnotationMutationVariables,
  APITypes.UpdateAnnotationMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory($input: UpdateCategory!) {
  updateCategory(input: $input) {
    categoryName
    id
    isAlertEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    clientName
    customerId
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const updateClientGroup = /* GraphQL */ `mutation UpdateClientGroup($input: UpdateClientGroupInput) {
  updateClientGroup(input: $input) {
    clientId
    createdAt
    customerId
    groupName
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientGroupMutationVariables,
  APITypes.UpdateClientGroupMutation
>;
export const updateCustomer = /* GraphQL */ `mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    aggAlertingData
    allowLeakFinder
    auditCustomers
    configuration
    domains
    externalId
    id
    name
    pointOfContact
    totalLeakFinderLimit
    usageLeakFinderCount
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerMutationVariables,
  APITypes.UpdateCustomerMutation
>;
export const updateCustomerDTObject = /* GraphQL */ `mutation UpdateCustomerDTObject($input: DTObjectInput!) {
  updateCustomerDTObject(input: $input) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomerDTObjectMutationVariables,
  APITypes.UpdateCustomerDTObjectMutation
>;
export const updateDTProject = /* GraphQL */ `mutation UpdateDTProject($input: UpdateDTProjectInput!) {
  updateDTProject(input: $input) {
    configURL
    customerId
    displayName
    id
    kmzLayerURL
    lastModificationDate
    lastModifierUserName
    measurementSystem
    projectId
    withKMZLayer
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDTProjectMutationVariables,
  APITypes.UpdateDTProjectMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice($input: UpdateDeviceInput!) {
  updateDevice(input: $input) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const updateDeviceData = /* GraphQL */ `mutation UpdateDeviceData(
  $customerId: String!
  $deviceData: AWSJSON
  $deviceId: String!
) {
  updateDeviceData(
    customerId: $customerId
    deviceData: $deviceData
    deviceId: $deviceId
  ) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceDataMutationVariables,
  APITypes.UpdateDeviceDataMutation
>;
export const updateDeviceHealth = /* GraphQL */ `mutation UpdateDeviceHealth(
  $deviceId: String!
  $isOnline: Boolean
  $locationId: String!
) {
  updateDeviceHealth(
    deviceId: $deviceId
    isOnline: $isOnline
    locationId: $locationId
  ) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceHealthMutationVariables,
  APITypes.UpdateDeviceHealthMutation
>;
export const updateDeviceMake = /* GraphQL */ `mutation UpdateDeviceMake($input: UpdateDeviceMakeInput!) {
  updateDeviceMake(input: $input) {
    defaultUserPwd
    deviceTypeShortName
    hasPanTilt
    hasZoom
    id
    model
    modelsToRun
    name
    rtspTemplate
    type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMakeMutationVariables,
  APITypes.UpdateDeviceMakeMutation
>;
export const updateLicensePlateWithNewCategory = /* GraphQL */ `mutation UpdateLicensePlateWithNewCategory($input: UpdateLicensePlate!) {
  updateLicensePlateWithNewCategory(input: $input) {
    categoryId
    company
    driverName
    id
    licensePlate
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLicensePlateWithNewCategoryMutationVariables,
  APITypes.UpdateLicensePlateWithNewCategoryMutation
>;
export const updateLocation = /* GraphQL */ `mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    locationData
    name
    referenceId
    tags {
      associationId
      id
      tagName
      __typename
    }
    timeZone
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLocationMutationVariables,
  APITypes.UpdateLocationMutation
>;
export const updateNode = /* GraphQL */ `mutation UpdateNode($input: UpdateNodeInput!) {
  updateNode(input: $input) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeMutationVariables,
  APITypes.UpdateNodeMutation
>;
export const updateNodeOnlineStatus = /* GraphQL */ `mutation UpdateNodeOnlineStatus($isOnline: Boolean, $nodeId: String!) {
  updateNodeOnlineStatus(isOnline: $isOnline, nodeId: $nodeId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNodeOnlineStatusMutationVariables,
  APITypes.UpdateNodeOnlineStatusMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile($input: ProfileInput) {
  updateProfile(input: $input) {
    file
    id
    name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const updateService = /* GraphQL */ `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceMutationVariables,
  APITypes.UpdateServiceMutation
>;
export const updateServiceHealth = /* GraphQL */ `mutation UpdateServiceHealth(
  $isOnline: Boolean
  $nodeId: String!
  $serviceId: String!
) {
  updateServiceHealth(
    isOnline: $isOnline
    nodeId: $nodeId
    serviceId: $serviceId
  ) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceHealthMutationVariables,
  APITypes.UpdateServiceHealthMutation
>;
export const updateShadowNode = /* GraphQL */ `mutation UpdateShadowNode(
  $payload: AWSJSON
  $shadowName: String
  $thingName: String
) {
  updateShadowNode(
    payload: $payload
    shadowName: $shadowName
    thingName: $thingName
  ) {
    shadow
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShadowNodeMutationVariables,
  APITypes.UpdateShadowNodeMutation
>;
export const updateUserAlert = /* GraphQL */ `mutation UpdateUserAlert($input: UpdateUserAlertInput!) {
  updateUserAlert(input: $input) {
    alertName
    alertType
    createdBy
    customService
    email
    id
    jsonObject
    location
    locationTag
    model
    node
    notificationSettingsJson
    phoneNumber
    subscribedLevelEntity
    tag
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserAlertMutationVariables,
  APITypes.UpdateUserAlertMutation
>;
export const updateUserSettings = /* GraphQL */ `mutation UpdateUserSettings(
  $customerId: String!
  $userId: String!
  $userSettings: UserSettingsInput!
) {
  updateUserSettings(
    customerId: $customerId
    userId: $userId
    userSettings: $userSettings
  ) {
    customer_id
    email
    externalId
    firstName
    id
    lastName
    userSettings
    user_role
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSettingsMutationVariables,
  APITypes.UpdateUserSettingsMutation
>;
export const updateVideoNotes = /* GraphQL */ `mutation UpdateVideoNotes($input: UpdateVideoNotesInput!) {
  updateVideoNotes(input: $input) {
    annotations
    clientId
    customerId
    fov
    geometry
    groupId
    id
    isVideoProcessed
    modelOutputS3Key
    notes
    outputMeasurement
    sourceS3Key
    videoMethod
    videoName
    videoState
    windspeed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVideoNotesMutationVariables,
  APITypes.UpdateVideoNotesMutation
>;
export const updateZone = /* GraphQL */ `mutation UpdateZone($input: UpdateZoneInput!) {
  updateZone(input: $input) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZoneMutationVariables,
  APITypes.UpdateZoneMutation
>;
export const updateZoneThresholdItem = /* GraphQL */ `mutation UpdateZoneThresholdItem($input: UpdateZoneThresholdItemInput!) {
  updateZoneThresholdItem(input: $input) {
    id
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZoneThresholdItemMutationVariables,
  APITypes.UpdateZoneThresholdItemMutation
>;
export const updateZoneThresholdValue = /* GraphQL */ `mutation UpdateZoneThresholdValue($input: UpdateZoneThresholdValueInput!) {
  updateZoneThresholdValue(input: $input) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZoneThresholdValueMutationVariables,
  APITypes.UpdateZoneThresholdValueMutation
>;
export const uploadDTFile = /* GraphQL */ `mutation UploadDTFile(
  $customerId: String
  $file: UploadFileInput!
  $objectId: String!
) {
  uploadDTFile(customerId: $customerId, file: $file, objectId: $objectId) {
    key
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UploadDTFileMutationVariables,
  APITypes.UploadDTFileMutation
>;
export const uploadDTFiles = /* GraphQL */ `mutation UploadDTFiles(
  $customerId: String
  $files: [UploadFileInput!]!
  $objectId: String!
) {
  uploadDTFiles(customerId: $customerId, files: $files, objectId: $objectId) {
    key
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UploadDTFilesMutationVariables,
  APITypes.UploadDTFilesMutation
>;
export const validateEvent = /* GraphQL */ `mutation ValidateEvent($input: ValidateEventInput!) {
  validateEvent(input: $input) {
    audited_by
    auditorsExplanation
    customerId
    data
    eventTime
    explanation
    id
    locationId
    nodeId
    serviceId
    transitionInfo
    validated_by
    validationState
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ValidateEventMutationVariables,
  APITypes.ValidateEventMutation
>;
