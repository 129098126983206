import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteClientGroupInput,
  DeleteClientGroupMutation,
  DeleteClientGroupMutationVariables,
} from "../../API";
import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import { useDeleteFolderFromS3 } from "../../common/hooks/useDeleteFolderFromS3";
import { DELETE_CLIENT_GROUP } from "../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";

export const useDeleteClientGroup = (silentDeleting = false) => {
  const customerId = useCustomerIdGuard();
  const { deleteFolderFromS3 } = useDeleteFolderFromS3();

  const [removeGroup, { data, loading }] = useMutation<
    DeleteClientGroupMutation,
    DeleteClientGroupMutationVariables
  >(DELETE_CLIENT_GROUP, {
    onError: (error: ApolloError) => {
      errorNotification(error.message);

      console.error(error.message);
    },
    update: (cache, { data }) => {
      if (data?.deleteClientGroup) {
        const normalizedId = cache.identify({
          id: data.deleteClientGroup.id,
          __typename: "Group",
        });

        cache.evict({ id: normalizedId });

        cache.gc();
      }
    },
  });

  const deleteClientGroup = async (input: DeleteClientGroupInput) => {
    const folderToDelete = `${customerId}/${input.clientId}/${input.groupId}`;

    try {
      await deleteFolderFromS3({ folderPath: folderToDelete });
    } catch (error) {
      console.error(error);
    }

    return removeGroup({
      variables: { input },
    }).then(response => {
      if (response.data?.deleteClientGroup && !silentDeleting) {
        successNotification(
          `${response.data.deleteClientGroup.groupName} is deleted`
        );
      }

      return response;
    });
  };

  return { deleteClientGroup, data, loading };
};
