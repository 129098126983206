import { useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";

import {
  allowedFileTypes,
  getFileExtensionFromSignedUrl,
} from "../../../../../common/components/media/fileHelper";
import KeyFrameMessage from "../../../../../common/components/media/NoKeyFrameMessage";
import TableCellWithPhotoIconOnHover from "../../../../../common/components/table/TableCellWithPhotoIconOnHover";
import TableCellWithVideocamIconOnHover from "../../../../../common/components/table/TableCellWithVideocamIconOnHover";

interface RecentEventsHoverIconsProps {
  keyFrame: string;
  mediaOutput: string;
  onClick?: () => void;
}

const RecentEventsHoverIcons = ({
  keyFrame,
  mediaOutput,
  onClick,
}: RecentEventsHoverIconsProps) => {
  const [error, setError] = useState(!keyFrame && !mediaOutput);

  const fileExtension = getFileExtensionFromSignedUrl(mediaOutput);

  const isVideo = allowedFileTypes.videos.includes(fileExtension);

  const s3Key = keyFrame ?? mediaOutput;

  return (
    <Box
      sx={{
        "& .hoverIcons": {
          display: "none",
          flex: 1,
          cursor: "pointer",
        },
        "&:hover .hoverIcons": {
          display: "flex",
          flex: 1,
          gap: "0.75em",
          position: "absolute",

          top: "33px",
          left: isVideo ? "32px" : "48px",
        },
        "& .videocamIcon": {
          zIndex: 1,
        },
      }}
    >
      <Box className="hoverIcons">
        <TableCellWithPhotoIconOnHover
          className="photoIcon"
          onClick={onClick}
        />
        {isVideo && (
          <TableCellWithVideocamIconOnHover className="videocamIcon" />
        )}
      </Box>
      <Box onClick={onClick}>
        {error ? (
          <KeyFrameMessage message="Please wait for the keyframe to retrieve" />
        ) : (
          <CardMedia
            sx={{
              borderRadius: "4px",
            }}
            src={s3Key}
            width={120}
            height={90}
            component="img"
            onError={() => setError(true)}
          />
        )}
      </Box>
    </Box>
  );
};

export default RecentEventsHoverIcons;
