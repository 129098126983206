import { MouseEvent, useState } from "react";

import { GridRowParams, MuiEvent } from "@mui/x-data-grid";

interface IUseRowTLMClick {
  open: boolean;
  tanksData: any;
  timestamp: any;
  mediaOutput: any;
  closeDialog: () => void;
  handleRowClick: (params: GridRowParams, event: MuiEvent<MouseEvent>) => void;
}

export const useRowClickTLM = (): IUseRowTLMClick => {
  const [open, setOpen] = useState(false);
  const [mediaOutput, setMediaOutput] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [tanksData, setTanksData] = useState("");

  const closeDialog = (): void => {
    setTanksData("");

    setOpen(false);
  };

  const handleRowClick = (
    params: GridRowParams,
    event: MuiEvent<MouseEvent>
  ): void => {
    if (event.defaultMuiPrevented) {
      return;
    }

    const tanksData = params?.row?.tanksData;

    const mediaUrl = params?.row?.signedUrlMediaOutput;

    setOpen(true);

    setTimestamp(params?.row?.timestamp);

    setMediaOutput(mediaUrl ?? "");

    try {
      setTanksData(JSON.parse(tanksData) ?? "");
    } catch (error) {
      console.error("tanksData is not a valid JSON string");
    }
  };

  return {
    open,
    tanksData,
    timestamp,
    mediaOutput,
    closeDialog,
    handleRowClick,
  };
};
