import axios, { AxiosInstance, AxiosResponse } from "axios";
import environment from "../../../../../environment.json";
import { getIdToken, getJwtToken } from "../utils/getJwtToken";
import { Tenant } from "../types/tenant";
import { AuthUser } from "../types/user";

export class AuthApiClient {
  private axiosInstance: AxiosInstance;

  constructor() {
    let envPath = "/dev"; // DON'T FORGET

    switch (environment.name) {
      case "prod":
        envPath = "/prod";
        break;

      case "staging":
        envPath = "/nftstage";

        break;

      default:
        break;
    }

    this.axiosInstance = axios.create({
      baseURL:
        "https://" + environment.stacks.webapp.domain.apiGateway + envPath,
    });
  }

  public async getUsers(): Promise<AuthUser[]> {
    try {
      const response: AxiosResponse<AuthUser[]> = await this.axiosInstance.get(
        "/users",
        { headers: await this.getHeaders() }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  }

  public async getCurentUser(): Promise<AuthUser> {
    try {
      const response: AxiosResponse<{ data: AuthUser }> =
        await this.axiosInstance.get("/users/current", {
          headers: await this.getHeaders(),
        });

      return response.data.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  }

  public async getCustomers(): Promise<Tenant[]> {
    try {
      const response: AxiosResponse<Tenant[]> = await this.axiosInstance.get(
        "/tenants",
        { headers: await this.getHeaders() }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  }

  public async getCustomer(id: string): Promise<Tenant> {
    try {
      const response: AxiosResponse<Tenant> = await this.axiosInstance.get(
        "/tenant/" + id,
        { headers: await this.getHeaders() }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  }

  private async getHeaders(): Promise<Record<string, string>> {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };

    const idToken = await getIdToken();
    const accessToken = await getJwtToken();

    if (accessToken && idToken) {
      headers["authorization"] = `Bearer ${accessToken}`;

      headers["id-token"] = idToken;
    } else {
      // DON'T FORGET - get token or unauthorize
    }

    return headers;
  }
}
