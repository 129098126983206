import SetFavicon from "./SetFavicon";
import withApolloProvider from "./common/providers/ApolloProvider";
import withAuthenticatorProvider from "./common/providers/AuthenticatorProvider";
import withNotificationMessageProvider from "./common/providers/NotificationMessageProvider";
import withThemeProvider from "./common/providers/theme/ThemeProvider";
import AppRoutes from "./routes/AppRoutes";

const App = (): JSX.Element => {
  return (
    <>
      <AppRoutes />
      <SetFavicon />
    </>
  );
};

export default withApolloProvider(
  withAuthenticatorProvider(
    withThemeProvider(withNotificationMessageProvider(App))
  )
);
