import { RoleEnum, RouteEnum } from "../models/enums";

export function isLocalEnvironment(): boolean {
  if (typeof window === "undefined") return false;

  const { hostname } = window.location;

  return (
    hostname === "localhost" ||
    hostname === "127.0.0.1" ||
    hostname.startsWith("192.168.") ||
    hostname.startsWith("0.0.0.")
  );
}

export function getRolePath(role: RoleEnum) {
  switch (role) {
    case RoleEnum.ROOT:
    case RoleEnum.PARTNER_ADMIN:
    case RoleEnum.CC_OPS_ADMIN:
    case RoleEnum.CUSTOMER_ADMIN:
    case RoleEnum.CC_OPS:
      return RouteEnum.Devices;

    case RoleEnum.CC_PROCTORS:
      return RouteEnum.HumanValidator;

    case RoleEnum.LEAKFINDER_DIRECTOR:
      return RouteEnum.LeakFinderClientUploads;

    case RoleEnum.PARTNER_USER:
    case RoleEnum.CUSTOMER_OPERATOR_FOREMAN:
      return RouteEnum.SensoryRecentActivities;

    case RoleEnum.NONE:
    case RoleEnum.PENDING:
      return RouteEnum.Unauthorized;

    default:
      return RouteEnum.Home;
  }
}
