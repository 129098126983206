import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import CardMediaVideoPlayerWithSpeedControls from "../../../pages/media-viewer/components/player/CardMediaVideoPlayerWithSpeedControls";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { allowedFileTypes, getFileExtensionFromSignedUrl } from "./fileHelper";

interface MediaCardDialogProps {
  signedUrl: string;
  open: boolean;
  fullWidth?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const MediaCardDialog: FC<MediaCardDialogProps> = ({
  signedUrl,
  open,
  fullWidth,
  onClose,
  children,
}) => {
  const theme = useTheme();

  const { width: screenWidth } = useWindowDimensions();

  const getMaxWidth = () => {
    if (fullWidth) {
      return "100%";
    }

    if (screenWidth > theme.breakpoints.values.xxl) {
      return theme.breakpoints.values.xl + "px";
    }

    return theme.breakpoints.values.lg + "px";
  };

  const fileExtension = getFileExtensionFromSignedUrl(signedUrl);

  const isImage = allowedFileTypes.images.some(
    (allowedImageExtension): boolean => {
      return allowedImageExtension === fileExtension;
    }
  );

  const isVideo = allowedFileTypes.videos.some(
    (allowedVideoExtension): boolean => {
      return allowedVideoExtension === fileExtension;
    }
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: 0,
          maxWidth: getMaxWidth(),
          maxHeight: getMaxWidth(),
          width: "100%",
          height: "100%",
          borderRadius: fullWidth ? "0" : "8px",
        },
      }}
    >
      <Box sx={{ position: "absolute", zIndex: 3, right: 8, top: 8 }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children ? (
        children
      ) : (
        <>
          <DialogContent
            sx={{
              padding: 0,
              margin: "0 auto",
              overflowX: "auto",
              width: "max-content",
            }}
          >
            {isImage && open && (
              <CardMedia
                sx={{
                  height: fullWidth ? "100vh" : "calc(100vh - 64px)",
                }}
                src={signedUrl}
                component="img"
              />
            )}
            {isVideo && open && (
              <CardMediaVideoPlayerWithSpeedControls
                sx={{
                  height: fullWidth ? "100vh" : "calc(100vh - 64px)",
                }}
                controls
                src={signedUrl}
                autoPlay
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MediaCardDialog;
