import { FC, useEffect, useRef, useState } from "react";

import { Box, Button } from "@mui/material";

import { useCachedUserSettings } from "../../../../leak-finder/hooks/useCachedUserSettings";
import { videoSpeedOptions } from "../../../system-settings/tabs/user-preferences/components/video-speed/VideoSpeedSection";
import CardMediaVideoPlayer, {
  ICardMediaVideoPlayerProps,
} from "./CardMediaVideoPlayer";

interface ICardMediaVideoPlayerWithControlsProps
  extends ICardMediaVideoPlayerProps {}

const CardMediaVideoPlayerWithSpeedControls: FC<
  ICardMediaVideoPlayerWithControlsProps
> = props => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { userSettings } = useCachedUserSettings();

  const [videoSpeed, setVideoSpeed] = useState(userSettings?.defaultVideoSpeed);

  const [showControls, setShowControls] = useState(false);

  const hideTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.onratechange = event => {
        const target = event.target as HTMLVideoElement;

        handleSpeedChange(target.playbackRate)();
      };
    }
  }, []);

  const handleMouseMovement = () => {
    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
    }

    setShowControls(true);

    hideTimer.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (hideTimer.current) {
        clearTimeout(hideTimer.current);
      }
    };
  }, []);

  const handleSpeedChange = (speed: number) => () => {
    if (videoRef && "current" in videoRef && videoRef.current) {
      videoRef.current.playbackRate = speed;

      videoRef.current.className = `${videoRef.current.className} active`;

      setVideoSpeed(speed);
    }
  };

  const handleSetPlaybackRate = (): void => {
    if (
      videoRef &&
      "current" in videoRef &&
      videoRef.current &&
      props.autoPlay
    ) {
      videoRef.current.playbackRate = userSettings?.defaultVideoSpeed as number;

      videoRef.current.muted = true; // Mute the video; this is required for autoplay

      // Attempt to autoplay the video: https://developer.chrome.com/blog/autoplay/#new_behaviors
      const promise = videoRef.current.play();

      if (promise !== undefined) {
        promise
          .then(() => {
            console.info("Autoplay started");
          })
          .catch(() => {
            console.warn("Autoplay was prevented.");
          });
      }
    }
  };

  return (
    <Box
      onMouseMove={handleMouseMovement}
      onMouseLeave={() => setShowControls(false)}
      sx={{
        position: "relative",
        "& #speed": {
          position: "absolute",
          bottom: 24,
          right: `calc(${48 * 3}px + 16px)`,

          "& button": {
            minWidth: "40px",
            width: "40px",
            padding: "0.25em",
            color: "rgba(255, 255, 255, 0.5)",
            background: "transparent",

            "&:hover, &.active": {
              color: theme => theme.palette.otherBackgroundLight.main,
              fontWeight: "bold",
            },
          },
        },
        "video::-webkit-media-controls-panel": {
          display: "flex !important",
          opacity: "1 !important",
        },
      }}
    >
      <CardMediaVideoPlayer
        sx={props.sx}
        controls={props.controls}
        src={props.src}
        autoPlay={props.autoPlay}
        onLoadedData={handleSetPlaybackRate}
        {...props}
        ref={(el: HTMLVideoElement | null) => {
          if (el) {
            videoRef.current = el; // Assign the video element to the ref

            return videoRef;
          }

          return null;
        }}
      />
      {showControls && (
        <div id="speed">
          {videoSpeedOptions.map(option => {
            return (
              <Button
                key={option.value}
                id={`speed-${option.value}`}
                className={option.value === videoSpeed ? "active" : ""}
                disableRipple
                onClick={handleSpeedChange(option.value)}
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default CardMediaVideoPlayerWithSpeedControls;
