/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAllAlerts = /* GraphQL */ `query GetAllAlerts($customerId: String!, $limit: Int, $nextToken: String) {
  getAllAlerts(customerId: $customerId, limit: $limit, nextToken: $nextToken) {
    items {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAlertsQueryVariables,
  APITypes.GetAllAlertsQuery
>;
export const getAllEmailsForSubscriptionEntity = /* GraphQL */ `query GetAllEmailsForSubscriptionEntity(
  $entity: String!
  $limit: Int
  $nextToken: String
) {
  getAllEmailsForSubscriptionEntity(
    entity: $entity
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      entity
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllEmailsForSubscriptionEntityQueryVariables,
  APITypes.GetAllEmailsForSubscriptionEntityQuery
>;
export const getAllSubscriptions = /* GraphQL */ `query GetAllSubscriptions($entity: String!, $limit: Int, $nextToken: String) {
  getAllSubscriptions(entity: $entity, limit: $limit, nextToken: $nextToken) {
    items {
      email
      entity
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSubscriptionsQueryVariables,
  APITypes.GetAllSubscriptionsQuery
>;
export const getAllVideosByClient = /* GraphQL */ `query GetAllVideosByClient($input: GetAllVideosByClientInput) {
  getAllVideosByClient(input: $input) {
    items {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllVideosByClientQueryVariables,
  APITypes.GetAllVideosByClientQuery
>;
export const getAllVideosByGroup = /* GraphQL */ `query GetAllVideosByGroup($input: GetAllVideosByGroupInput!) {
  getAllVideosByGroup(input: $input) {
    items {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllVideosByGroupQueryVariables,
  APITypes.GetAllVideosByGroupQuery
>;
export const getAllVideosByState = /* GraphQL */ `query GetAllVideosByState($input: GetAllVideosByStateInput!) {
  getAllVideosByState(input: $input) {
    items {
      annotations
      clientId
      customerId
      fov
      geometry
      groupId
      id
      isVideoProcessed
      modelOutputS3Key
      notes
      outputMeasurement
      sourceS3Key
      videoMethod
      videoName
      videoState
      windspeed
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllVideosByStateQueryVariables,
  APITypes.GetAllVideosByStateQuery
>;
export const getAllWildCardsForSubscriptionEntity = /* GraphQL */ `query GetAllWildCardsForSubscriptionEntity($limit: Int, $nextToken: String) {
  getAllWildCardsForSubscriptionEntity(limit: $limit, nextToken: $nextToken) {
    items {
      email
      entity
      wildCard
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllWildCardsForSubscriptionEntityQueryVariables,
  APITypes.GetAllWildCardsForSubscriptionEntityQuery
>;
export const getAnnotations = /* GraphQL */ `query GetAnnotations($input: GetAnnotationsInput!) {
  getAnnotations(input: $input) {
    items {
      annotateTanks
      annotationType
      bottomHidden
      deviceId
      equipmentType
      id
      name
      polygon
      serviceId
      tankHeight
      tankNumber
      zoneId
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAnnotationsQueryVariables,
  APITypes.GetAnnotationsQuery
>;
export const getCategoriesByCustomer = /* GraphQL */ `query GetCategoriesByCustomer($input: GetCategoriesByCustomerInput!) {
  getCategoriesByCustomer(input: $input) {
    items {
      categoryName
      id
      isAlertEnabled
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoriesByCustomerQueryVariables,
  APITypes.GetCategoriesByCustomerQuery
>;
export const getCategoryById = /* GraphQL */ `query GetCategoryById($input: GetCategoryInput!) {
  getCategoryById(input: $input) {
    categoryName
    id
    isAlertEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryByIdQueryVariables,
  APITypes.GetCategoryByIdQuery
>;
export const getClientById = /* GraphQL */ `query GetClientById($input: GetClientInput!) {
  getClientById(input: $input) {
    clientName
    customerId
    id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientByIdQueryVariables,
  APITypes.GetClientByIdQuery
>;
export const getClientsByCustomer = /* GraphQL */ `query GetClientsByCustomer($input: GetClientsByCustomerInput!) {
  getClientsByCustomer(input: $input) {
    items {
      clientName
      customerId
      id
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientsByCustomerQueryVariables,
  APITypes.GetClientsByCustomerQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($customerExternalId: String!, $customerId: String!) {
  getCustomer(
    customerExternalId: $customerExternalId
    customerId: $customerId
  ) {
    aggAlertingData
    allowLeakFinder
    auditCustomers
    configuration
    domains
    externalId
    id
    name
    pointOfContact
    totalLeakFinderLimit
    usageLeakFinderCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const getCustomerCounters = /* GraphQL */ `query GetCustomerCounters($customerExternalId: String!, $customerId: String!) {
  getCustomerCounters(
    customerExternalId: $customerExternalId
    customerId: $customerId
  ) {
    allowLeakFinder
    id
    totalLeakFinderLimit
    usageLeakFinderCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerCountersQueryVariables,
  APITypes.GetCustomerCountersQuery
>;
export const getCustomerDTObject = /* GraphQL */ `query GetCustomerDTObject($customerId: String, $objectId: String!) {
  getCustomerDTObject(customerId: $customerId, objectId: $objectId) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerDTObjectQueryVariables,
  APITypes.GetCustomerDTObjectQuery
>;
export const getCustomerDTObjects = /* GraphQL */ `query GetCustomerDTObjects($customerId: String) {
  getCustomerDTObjects(customerId: $customerId) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerDTObjectsQueryVariables,
  APITypes.GetCustomerDTObjectsQuery
>;
export const getCustomerHealth = /* GraphQL */ `query GetCustomerHealth($customerId: String) {
  getCustomerHealth(customerId: $customerId)
}
` as GeneratedQuery<
  APITypes.GetCustomerHealthQueryVariables,
  APITypes.GetCustomerHealthQuery
>;
export const getCustomers = /* GraphQL */ `query GetCustomers($limit: Int, $nextToken: String) {
  getCustomers(limit: $limit, nextToken: $nextToken) {
    items {
      aggAlertingData
      allowLeakFinder
      auditCustomers
      configuration
      domains
      externalId
      id
      name
      pointOfContact
      totalLeakFinderLimit
      usageLeakFinderCount
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomersQueryVariables,
  APITypes.GetCustomersQuery
>;
export const getDTCustomerProjects = /* GraphQL */ `query GetDTCustomerProjects(
  $customerId: String!
  $limit: Int
  $nextToken: String
) {
  getDTCustomerProjects(
    customerId: $customerId
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTCustomerProjectsQueryVariables,
  APITypes.GetDTCustomerProjectsQuery
>;
export const getDTFile = /* GraphQL */ `query GetDTFile($key: String) {
  getDTFile(key: $key) {
    key
    url
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDTFileQueryVariables, APITypes.GetDTFileQuery>;
export const getDTFiles = /* GraphQL */ `query GetDTFiles($keys: [String]) {
  getDTFiles(keys: $keys) {
    key
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTFilesQueryVariables,
  APITypes.GetDTFilesQuery
>;
export const getDTObject = /* GraphQL */ `query GetDTObject($objectId: String!) {
  getDTObject(objectId: $objectId) {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTObjectQueryVariables,
  APITypes.GetDTObjectQuery
>;
export const getDTObjects = /* GraphQL */ `query GetDTObjects {
  getDTObjects {
    filesInfo {
      contentType
      id
      key
      originalFileName
      useType
      __typename
    }
    id
    isCustomerObject
    objectId
    objectManufacturerName
    objectName
    objectType
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTObjectsQueryVariables,
  APITypes.GetDTObjectsQuery
>;
export const getDTProject = /* GraphQL */ `query GetDTProject($customerId: String!, $projectId: String!) {
  getDTProject(customerId: $customerId, projectId: $projectId) {
    configURL
    customerId
    displayName
    id
    kmzLayerURL
    lastModificationDate
    lastModifierUserName
    measurementSystem
    projectId
    withKMZLayer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTProjectQueryVariables,
  APITypes.GetDTProjectQuery
>;
export const getDTProjects = /* GraphQL */ `query GetDTProjects($limit: Int, $nextToken: String) {
  getDTProjects(limit: $limit, nextToken: $nextToken) {
    items {
      configURL
      customerId
      displayName
      id
      kmzLayerURL
      lastModificationDate
      lastModifierUserName
      measurementSystem
      projectId
      withKMZLayer
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTProjectsQueryVariables,
  APITypes.GetDTProjectsQuery
>;
export const getDTProjectsByUsedDTObject = /* GraphQL */ `query GetDTProjectsByUsedDTObject($customerId: String!, $objectId: String!) {
  getDTProjectsByUsedDTObject(customerId: $customerId, objectId: $objectId) {
    configURL
    customerId
    displayName
    id
    kmzLayerURL
    lastModificationDate
    lastModifierUserName
    measurementSystem
    projectId
    withKMZLayer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDTProjectsByUsedDTObjectQueryVariables,
  APITypes.GetDTProjectsByUsedDTObjectQuery
>;
export const getDeviceById = /* GraphQL */ `query GetDeviceById($customerId: String!, $deviceId: String!) {
  getDeviceById(customerId: $customerId, deviceId: $deviceId) {
    cameraIpAddress
    customerId
    deviceData {
      hasPanTilt
      hasRecordVideo
      hasZoom
      keyFrame
      pan
      panTiltIP
      protocol
      recentFrame
      rtspHost
      sourceVideo
      tilt
      zoom
      __typename
    }
    healthData {
      isOnline
      lastAlive
      totalDowntime
      totalUptime
      uptimeDate
      uptimePercent
      __typename
    }
    id
    locationId
    locationName
    makeModelId
    name
    nodeId
    nodeName
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceByIdQueryVariables,
  APITypes.GetDeviceByIdQuery
>;
export const getDeviceMakeById = /* GraphQL */ `query GetDeviceMakeById($makeModelId: String!) {
  getDeviceMakeById(makeModelId: $makeModelId) {
    defaultUserPwd
    deviceTypeShortName
    hasPanTilt
    hasZoom
    id
    model
    modelsToRun
    name
    rtspTemplate
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceMakeByIdQueryVariables,
  APITypes.GetDeviceMakeByIdQuery
>;
export const getDeviceMakeByModel = /* GraphQL */ `query GetDeviceMakeByModel($makeModelName: String!) {
  getDeviceMakeByModel(makeModelName: $makeModelName) {
    defaultUserPwd
    deviceTypeShortName
    hasPanTilt
    hasZoom
    id
    model
    modelsToRun
    name
    rtspTemplate
    type
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceMakeByModelQueryVariables,
  APITypes.GetDeviceMakeByModelQuery
>;
export const getDevices = /* GraphQL */ `query GetDevices(
  $customerId: String!
  $limit: Int
  $locationId: String
  $nextToken: String
  $nodeId: String
) {
  getDevices(
    customerId: $customerId
    limit: $limit
    locationId: $locationId
    nextToken: $nextToken
    nodeId: $nodeId
  ) {
    items {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDevicesQueryVariables,
  APITypes.GetDevicesQuery
>;
export const getDevicesByLocation = /* GraphQL */ `query GetDevicesByLocation($locationId: String!, $nextToken: String) {
  getDevicesByLocation(locationId: $locationId, nextToken: $nextToken) {
    items {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDevicesByLocationQueryVariables,
  APITypes.GetDevicesByLocationQuery
>;
export const getDevicesByNodeId = /* GraphQL */ `query GetDevicesByNodeId($nextToken: String, $nodeId: String!) {
  getDevicesByNodeId(nextToken: $nextToken, nodeId: $nodeId) {
    items {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDevicesByNodeIdQueryVariables,
  APITypes.GetDevicesByNodeIdQuery
>;
export const getDevicesMakes = /* GraphQL */ `query GetDevicesMakes($limit: Int, $nextToken: String) {
  getDevicesMakes(limit: $limit, nextToken: $nextToken) {
    items {
      defaultUserPwd
      deviceTypeShortName
      hasPanTilt
      hasZoom
      id
      model
      modelsToRun
      name
      rtspTemplate
      type
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDevicesMakesQueryVariables,
  APITypes.GetDevicesMakesQuery
>;
export const getEventsPendingHumanValidation = /* GraphQL */ `query GetEventsPendingHumanValidation($limit: Int, $nextToken: String) {
  getEventsPendingHumanValidation(limit: $limit, nextToken: $nextToken) {
    items {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventsPendingHumanValidationQueryVariables,
  APITypes.GetEventsPendingHumanValidationQuery
>;
export const getForm = /* GraphQL */ `query GetForm($customerId: String!, $itemId: String!) {
  getForm(customerId: $customerId, itemId: $itemId) {
    customerId
    fields {
      entries
      itemToQuery
      name
      type
      __typename
    }
    id
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormQueryVariables, APITypes.GetFormQuery>;
export const getGroupByClientId = /* GraphQL */ `query GetGroupByClientId($input: GetGroupByClientIdInput!) {
  getGroupByClientId(input: $input) {
    clientId
    createdAt
    customerId
    groupName
    id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupByClientIdQueryVariables,
  APITypes.GetGroupByClientIdQuery
>;
export const getGroupsByClient = /* GraphQL */ `query GetGroupsByClient($input: GetGroupsByClientInput!) {
  getGroupsByClient(input: $input) {
    items {
      clientId
      createdAt
      customerId
      groupName
      id
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupsByClientQueryVariables,
  APITypes.GetGroupsByClientQuery
>;
export const getHlsUrl = /* GraphQL */ `query GetHlsUrl($streamName: String!) {
  getHlsUrl(streamName: $streamName)
}
` as GeneratedQuery<APITypes.GetHlsUrlQueryVariables, APITypes.GetHlsUrlQuery>;
export const getHumanValidatedEventsForAuditing = /* GraphQL */ `query GetHumanValidatedEventsForAuditing($limit: Int, $nextToken: String) {
  getHumanValidatedEventsForAuditing(limit: $limit, nextToken: $nextToken) {
    items {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHumanValidatedEventsForAuditingQueryVariables,
  APITypes.GetHumanValidatedEventsForAuditingQuery
>;
export const getLicensePlate = /* GraphQL */ `query GetLicensePlate($categoryId: String!, $licensePlateId: String!) {
  getLicensePlate(categoryId: $categoryId, licensePlateId: $licensePlateId) {
    categoryId
    company
    driverName
    id
    licensePlate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLicensePlateQueryVariables,
  APITypes.GetLicensePlateQuery
>;
export const getLicensePlatesByCustomer = /* GraphQL */ `query GetLicensePlatesByCustomer($input: GetLicensePlatesByCustomerInput!) {
  getLicensePlatesByCustomer(input: $input) {
    items {
      categoryId
      company
      driverName
      id
      licensePlate
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLicensePlatesByCustomerQueryVariables,
  APITypes.GetLicensePlatesByCustomerQuery
>;
export const getLocationById = /* GraphQL */ `query GetLocationById($customerId: String!, $locationId: String!) {
  getLocationById(customerId: $customerId, locationId: $locationId) {
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    locationData
    name
    referenceId
    tags {
      associationId
      id
      tagName
      __typename
    }
    timeZone
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationByIdQueryVariables,
  APITypes.GetLocationByIdQuery
>;
export const getLocations = /* GraphQL */ `query GetLocations($customerId: String!, $limit: Int, $nextToken: String) {
  getLocations(customerId: $customerId, limit: $limit, nextToken: $nextToken) {
    items {
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          pan
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
          tilt
          zoom
          __typename
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
          __typename
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
        __typename
      }
      id
      locationData
      name
      referenceId
      tags {
        associationId
        id
        tagName
        __typename
      }
      timeZone
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationsQueryVariables,
  APITypes.GetLocationsQuery
>;
export const getLocationsByTag = /* GraphQL */ `query GetLocationsByTag(
  $customerId: String!
  $limit: Int
  $nextToken: String
  $tag: String!
) {
  getLocationsByTag(
    customerId: $customerId
    limit: $limit
    nextToken: $nextToken
    tag: $tag
  ) {
    items {
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          pan
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
          tilt
          zoom
          __typename
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
          __typename
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
        __typename
      }
      id
      locationData
      name
      referenceId
      tags {
        associationId
        id
        tagName
        __typename
      }
      timeZone
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationsByTagQueryVariables,
  APITypes.GetLocationsByTagQuery
>;
export const getNodeById = /* GraphQL */ `query GetNodeById($nodeId: String!) {
  getNodeById(nodeId: $nodeId) {
    customerId
    deviceData
    devices {
      cameraIpAddress
      customerId
      deviceData {
        hasPanTilt
        hasRecordVideo
        hasZoom
        keyFrame
        pan
        panTiltIP
        protocol
        recentFrame
        rtspHost
        sourceVideo
        tilt
        zoom
        __typename
      }
      healthData {
        isOnline
        lastAlive
        totalDowntime
        totalUptime
        uptimeDate
        uptimePercent
        __typename
      }
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
      __typename
    }
    id
    isOnline
    level
    locationId
    nodeIP
    nodeId
    nodeName
    onboardCommand
    referenceId
    serviceData
    tags {
      id
      tagName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodeByIdQueryVariables,
  APITypes.GetNodeByIdQuery
>;
export const getNodes = /* GraphQL */ `query GetNodes($limit: Int, $locationId: String!, $nextToken: String) {
  getNodes(limit: $limit, locationId: $locationId, nextToken: $nextToken) {
    items {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          pan
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
          tilt
          zoom
          __typename
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
          __typename
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
        __typename
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
        __typename
      }
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNodesQueryVariables, APITypes.GetNodesQuery>;
export const getNodesByCustomer = /* GraphQL */ `query GetNodesByCustomer(
  $customerId: String!
  $limit: Int
  $nextToken: String
) {
  getNodesByCustomer(
    customerId: $customerId
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          pan
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
          tilt
          zoom
          __typename
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
          __typename
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
        __typename
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
        __typename
      }
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodesByCustomerQueryVariables,
  APITypes.GetNodesByCustomerQuery
>;
export const getNodesByTag = /* GraphQL */ `query GetNodesByTag($limit: Int, $nextToken: String, $tagId: String!) {
  getNodesByTag(limit: $limit, nextToken: $nextToken, tagId: $tagId) {
    items {
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData {
          hasPanTilt
          hasRecordVideo
          hasZoom
          keyFrame
          pan
          panTiltIP
          protocol
          recentFrame
          rtspHost
          sourceVideo
          tilt
          zoom
          __typename
        }
        healthData {
          isOnline
          lastAlive
          totalDowntime
          totalUptime
          uptimeDate
          uptimePercent
          __typename
        }
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
        __typename
      }
      id
      isOnline
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
        __typename
      }
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNodesByTagQueryVariables,
  APITypes.GetNodesByTagQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile {
  getProfile {
    file
    id
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const getRoles = /* GraphQL */ `query GetRoles($limit: Int, $nextToken: String) {
  getRoles(limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      userGroup
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRolesQueryVariables, APITypes.GetRolesQuery>;
export const getSendEmail = /* GraphQL */ `query GetSendEmail($email: String) {
  getSendEmail(email: $email)
}
` as GeneratedQuery<
  APITypes.GetSendEmailQueryVariables,
  APITypes.GetSendEmailQuery
>;
export const getServiceById = /* GraphQL */ `query GetServiceById($input: GetServiceInput!) {
  getServiceById(input: $input) {
    configuration
    customerId
    deviceId
    healthData
    id
    isRunning
    locationId
    nodeId
    serviceType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceByIdQueryVariables,
  APITypes.GetServiceByIdQuery
>;
export const getServices = /* GraphQL */ `query GetServices($customerId: String, $limit: Int, $nextToken: String) {
  getServices(customerId: $customerId, limit: $limit, nextToken: $nextToken) {
    items {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesQueryVariables,
  APITypes.GetServicesQuery
>;
export const getServicesByDevice = /* GraphQL */ `query GetServicesByDevice($deviceId: String!, $nextToken: String) {
  getServicesByDevice(deviceId: $deviceId, nextToken: $nextToken) {
    items {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesByDeviceQueryVariables,
  APITypes.GetServicesByDeviceQuery
>;
export const getServicesByLocation = /* GraphQL */ `query GetServicesByLocation($locationId: String!, $nextToken: String) {
  getServicesByLocation(locationId: $locationId, nextToken: $nextToken) {
    items {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesByLocationQueryVariables,
  APITypes.GetServicesByLocationQuery
>;
export const getShadowNode = /* GraphQL */ `query GetShadowNode($shadowName: String, $thingName: String) {
  getShadowNode(shadowName: $shadowName, thingName: $thingName) {
    shadow
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShadowNodeQueryVariables,
  APITypes.GetShadowNodeQuery
>;
export const getSignedUrlAction = /* GraphQL */ `query GetSignedUrlAction($input: GetSignedUrlActionInput!) {
  getSignedUrlAction(input: $input)
}
` as GeneratedQuery<
  APITypes.GetSignedUrlActionQueryVariables,
  APITypes.GetSignedUrlActionQuery
>;
export const getStatisticsData = /* GraphQL */ `query GetStatisticsData($input: StatisticsDataInput) {
  getStatisticsData(input: $input)
}
` as GeneratedQuery<
  APITypes.GetStatisticsDataQueryVariables,
  APITypes.GetStatisticsDataQuery
>;
export const getSubscriptionsForEmail = /* GraphQL */ `query GetSubscriptionsForEmail(
  $email: String!
  $entity: String!
  $limit: Int
  $nextToken: String
) {
  getSubscriptionsForEmail(
    email: $email
    entity: $entity
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      entity
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubscriptionsForEmailQueryVariables,
  APITypes.GetSubscriptionsForEmailQuery
>;
export const getTagAssociations = /* GraphQL */ `query GetTagAssociations($input: GetTagAssociationsInput!) {
  getTagAssociations(input: $input) {
    items {
      associationId
      id
      tagName
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagAssociationsQueryVariables,
  APITypes.GetTagAssociationsQuery
>;
export const getTagsByCustomer = /* GraphQL */ `query GetTagsByCustomer($input: GetTagsByCustomerInput!) {
  getTagsByCustomer(input: $input) {
    items {
      id
      tagName
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagsByCustomerQueryVariables,
  APITypes.GetTagsByCustomerQuery
>;
export const getTimestreamData = /* GraphQL */ `query GetTimestreamData($input: TimestreamDataInput) {
  getTimestreamData(input: $input)
}
` as GeneratedQuery<
  APITypes.GetTimestreamDataQueryVariables,
  APITypes.GetTimestreamDataQuery
>;
export const getUserAlerts = /* GraphQL */ `query GetUserAlerts(
  $customerId: String!
  $email: String!
  $limit: Int
  $nextToken: String
) {
  getUserAlerts(
    customerId: $customerId
    email: $email
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      alertName
      alertType
      createdBy
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      notificationSettingsJson
      phoneNumber
      subscribedLevelEntity
      tag
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAlertsQueryVariables,
  APITypes.GetUserAlertsQuery
>;
export const getUserById = /* GraphQL */ `query GetUserById {
  getUserById {
    customer_id
    email
    externalId
    firstName
    id
    lastName
    userSettings
    user_role
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByIdQueryVariables,
  APITypes.GetUserByIdQuery
>;
export const getZone = /* GraphQL */ `query GetZone($customerId: String!, $deviceId: String!, $zoneId: String!) {
  getZone(customerId: $customerId, deviceId: $deviceId, zoneId: $zoneId) {
    alertData
    deviceId
    exclusions
    id
    inspectTime
    keyFrame
    name
    nodeId
    pan
    serviceId
    shouldNotify
    status
    threshold {
      id
      name
      value
      __typename
    }
    tilt
    zoom
    __typename
  }
}
` as GeneratedQuery<APITypes.GetZoneQueryVariables, APITypes.GetZoneQuery>;
export const getZoneThresholdById = /* GraphQL */ `query GetZoneThresholdById($input: GetZoneThresholdInput!) {
  getZoneThresholdById(input: $input) {
    id
    name
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZoneThresholdByIdQueryVariables,
  APITypes.GetZoneThresholdByIdQuery
>;
export const getZones = /* GraphQL */ `query GetZones(
  $customerId: String!
  $limit: Int
  $nextToken: String
  $serviceId: String!
) {
  getZones(
    customerId: $customerId
    limit: $limit
    nextToken: $nextToken
    serviceId: $serviceId
  ) {
    items {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
        __typename
      }
      tilt
      zoom
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.GetZonesQueryVariables, APITypes.GetZonesQuery>;
export const getZonesByDevice = /* GraphQL */ `query GetZonesByDevice(
  $customerId: String!
  $deviceId: String!
  $filterByCustomer: Boolean
  $limit: Int
  $nextToken: String
) {
  getZonesByDevice(
    customerId: $customerId
    deviceId: $deviceId
    filterByCustomer: $filterByCustomer
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      shouldNotify
      status
      threshold {
        id
        name
        value
        __typename
      }
      tilt
      zoom
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZonesByDeviceQueryVariables,
  APITypes.GetZonesByDeviceQuery
>;
export const getZonesThresholdsByCustomer = /* GraphQL */ `query GetZonesThresholdsByCustomer($input: GetZonesThresholdsByCustomerInput!) {
  getZonesThresholdsByCustomer(input: $input) {
    items {
      id
      name
      value
      __typename
    }
    limit
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZonesThresholdsByCustomerQueryVariables,
  APITypes.GetZonesThresholdsByCustomerQuery
>;
