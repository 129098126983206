import { useQuery } from "@apollo/client";

import { GetHlsUrlQuery, GetHlsUrlQueryVariables } from "../../API";
import { GET_HLS_URL } from "../../common/operations/queries";

export const useGetHlsStream = (streamName: string) => {
  const { data, loading, refetch } = useQuery<
    GetHlsUrlQuery,
    GetHlsUrlQueryVariables
  >(GET_HLS_URL, {
    variables: {
      streamName,
    },
    skip: !streamName,
  });

  let parsedResponse;
  let parsedBody;

  if (data) {
    parsedResponse = JSON.parse(data?.getHlsUrl ?? "");

    parsedBody = JSON.parse(parsedResponse?.body ?? "");
  }

  return {
    data: { ...parsedResponse, body: parsedBody },
    hlsUrl: parsedBody?.HLSStreamingSessionURL,
    loading: loading ?? true,
    refetch,
  };
};
