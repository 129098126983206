import { useEffect } from "react";

import { ApolloQueryResult, NetworkStatus, useLazyQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";

import {
  GetNodesByCustomerQuery,
  GetNodesByCustomerQueryVariables,
} from "../../../../API";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { GET_NODES_BY_CUSTOMER } from "../../../operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../variables/common";
import { errorNotification } from "../../../variables/notification";

export const useGetNodesByCustomer = () => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchNodes(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const [getNodes, { data, loading, fetchMore, networkStatus }] = useLazyQuery<
    GetNodesByCustomerQuery,
    GetNodesByCustomerQueryVariables
  >(GET_NODES_BY_CUSTOMER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const fetchNodes = async (customerId: string, nextToken?: string) => {
    try {
      let nextPageToken = null;

      const response = await getNodes({
        variables: {
          limit: DEFAULT_LIMIT_COUNT,
          customerId,
          nextToken,
        },
      });

      const responseNextToken = response?.data?.getNodesByCustomer.nextToken;

      if (responseNextToken) {
        nextPageToken = responseNextToken;

        // Fetch more results if nextToken is available
        while (nextPageToken) {
          const fetchMoreResults: ApolloQueryResult<GetNodesByCustomerQuery> =
            await fetchMore({
              variables: {
                limit: DEFAULT_LIMIT_COUNT,
                customerId,
                nextToken: nextPageToken,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const uniqueNodes = uniqBy(
                  [
                    ...(prev.getNodesByCustomer?.items ?? []),
                    ...(fetchMoreResult.getNodesByCustomer?.items ?? []),
                  ],
                  item => item?.id
                );

                return {
                  getNodesByCustomer: {
                    ...fetchMoreResult.getNodesByCustomer,
                    items: uniqueNodes,
                  },
                };
              },
            });

          const fetchMoreResultsNextToken =
            fetchMoreResults?.data?.getNodesByCustomer?.nextToken;

          if (fetchMoreResultsNextToken) {
            nextPageToken = fetchMoreResultsNextToken;
          } else {
            nextPageToken = null;
          }
        }
      }
    } catch (error) {
      console.error(`Error while fetching devices: ${error}`);

      errorNotification();
    }
  };

  return {
    data,
    loading: loading || networkStatus === NetworkStatus.fetchMore,
  };
};
