import { useParams } from "react-router-dom";

import FireEventsServerTableContainer from "./models/fire/FireEventsServerTableContainer";
import GasLeakTableServerContainer from "./models/gasleak/GasLeakTableServerContainer";
import GateGuardServerTableContainer from "./models/gateGuard/GateGuardServerTableContainer";
import HardHatEventsServerTableContainer from "./models/hardhat/HardHatEventsServerTableContainer";
import LiquidLeakServerTableContainer from "./models/liquidleak/LiquidLeakServerTableContainer";
import SmokeEventsServerTableContainer from "./models/smoke/SmokeEventsServerTableContainer";
import TlmEventsServerTableContainer from "./models/tlm/TlmEventsServerTableContainer";

const DataHubPageContent = (): JSX.Element => {
  const { model } = useParams();

  let content = <></>;

  switch (model) {
    case "gasleak":
      content = <GasLeakTableServerContainer />;
      break;
    case "liquidleak":
      content = <LiquidLeakServerTableContainer />;
      break;
    case "hardhat":
      content = <HardHatEventsServerTableContainer />;
      break;
    case "fire":
      content = <FireEventsServerTableContainer />;
      break;
    case "smoke":
      content = <SmokeEventsServerTableContainer />;
      break;
    case "agg":
      content = <GateGuardServerTableContainer />;
      break;

    case "tlm":
      content = <TlmEventsServerTableContainer />;
      break;

    default:
      break;
  }

  return content;
};

export default DataHubPageContent;
