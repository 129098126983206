import { MouseEvent, useRef, useState } from "react";

import { GridCellParams, GridRowParams, MuiEvent } from "@mui/x-data-grid";

interface IUseRowClick {
  open: boolean;
  signedUrl: string;
  closeDialog: () => void;
  handleRowClick: (
    key?: string
  ) => (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => void;
  handleKeyFrameClick: (params: GridCellParams) => void;
  handleMediaOutputClick: (params: GridRowParams) => void;
}

export const useRowClick = (): IUseRowClick => {
  const keyFrameRef = useRef(false);

  const [open, setOpen] = useState(false);

  const [signedUrl, setSignedUrl] = useState("");

  const closeDialog = (): void => {
    setSignedUrl("");

    keyFrameRef.current = false;

    setOpen(false);
  };

  const handleRowClick =
    (key = "signedUrlMediaOutput") =>
    (params: GridRowParams, event: MuiEvent<MouseEvent>) => {
      if (event.defaultMuiPrevented) {
        return;
      }

      const mediaUrl = params?.row?.[key];

      setSignedUrl(mediaUrl);

      setOpen(true);
    };

  const handleKeyFrameClick = (params: GridCellParams): void => {
    keyFrameRef.current = true;

    const mediaUrl = params?.row?.signedUrlKeyFrame;

    setSignedUrl(mediaUrl);

    setOpen(true);
  };

  const handleMediaOutputClick = (params: GridRowParams): void => {
    // prevent the row click event from firing if the keyFrame was clicked
    if (keyFrameRef.current) {
      return;
    }

    const mediaUrl = params?.row?.signedUrlMediaOutput;

    setSignedUrl(mediaUrl);

    setOpen(true);
  };

  return {
    open,
    signedUrl,
    closeDialog,
    handleRowClick,
    handleKeyFrameClick,
    handleMediaOutputClick,
  };
};
