import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ApolloQueryResult, NetworkStatus, useLazyQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";

import {
  GetZonesByDeviceQuery,
  GetZonesByDeviceQueryVariables,
} from "../../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import { GET_ZONES_BY_DEVICE } from "../../../../../../../common/operations/queries";
import { DEFAULT_LIMIT_COUNT } from "../../../../../../../common/variables/common";
import { errorNotification } from "../../../../../../../common/variables/notification";

export const useGetZonesByDevice = () => {
  const selectedCustomerId = useCustomerIdGuard();
  const { deviceId = "" } = useParams();

  const { state } = useLocation();
  const selectedDeviceId = state?.deviceId ?? decodeURIComponent(deviceId);

  useEffect(() => {
    if (selectedDeviceId && selectedCustomerId) {
      fetchZones(selectedDeviceId);
    }
  }, [selectedCustomerId, selectedDeviceId]);

  const [getZonesByDevice, { data, loading, fetchMore, networkStatus }] =
    useLazyQuery<GetZonesByDeviceQuery, GetZonesByDeviceQueryVariables>(
      GET_ZONES_BY_DEVICE,
      {
        fetchPolicy: "network-only",
      }
    );

  const fetchZones = async (deviceId: string, nextToken?: string) => {
    try {
      let nextPageToken = null;

      const response = await getZonesByDevice({
        variables: {
          limit: DEFAULT_LIMIT_COUNT,
          // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
          deviceId: selectedCustomerId + "#" + deviceId,
          customerId: selectedCustomerId,
          filterByCustomer: false,
          nextToken,
        },
      });

      const responseNextToken = response?.data?.getZonesByDevice.nextToken;

      if (responseNextToken) {
        nextPageToken = responseNextToken;

        // Fetch more results if nextToken is available
        while (nextPageToken) {
          const fetchMoreResults: ApolloQueryResult<GetZonesByDeviceQuery> =
            await fetchMore({
              variables: {
                limit: DEFAULT_LIMIT_COUNT,
                // temporary fix until we fix DDB schemas with new C# prefix for querying zones, annotations, etc. (this would involve adding the prefix for device and service creation)
                deviceId: selectedCustomerId + "#" + deviceId,
                customerId: selectedCustomerId,
                nextToken: nextPageToken,
                filterByCustomer: false,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const uniqueZones = uniqBy(
                  [
                    ...(prev.getZonesByDevice?.items ?? []),
                    ...(fetchMoreResult.getZonesByDevice?.items ?? []),
                  ],
                  item => item?.id
                );

                return {
                  getZonesByDevice: {
                    ...fetchMoreResult.getZonesByDevice,
                    items: uniqueZones,
                  },
                };
              },
            });

          const fetchMoreResultsNextToken =
            fetchMoreResults?.data?.getZonesByDevice?.nextToken;

          if (fetchMoreResultsNextToken) {
            nextPageToken = fetchMoreResultsNextToken;
          } else {
            nextPageToken = null;
          }
        }
      }
    } catch (error) {
      console.error(`Error while fetching devices: ${error}`);

      errorNotification();
    }
  };

  return {
    data,
    loading: loading || networkStatus === NetworkStatus.fetchMore,
  };
};
