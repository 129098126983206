import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";

import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { useLocationsTableColumns } from "../hooks/useLocationsTableColumns";
import { useLocationsTableData } from "../hooks/useLocationsTableData";

export const DEFAULT_TABLE_PAGE_SIZE = 10;

const LocationsTable = (): JSX.Element => {
  const { rows, loading } = useLocationsTableData();
  const { columns } = useLocationsTableColumns();

  // eslint-disable-next-line
  const handleRowClick = (params: any): void => {
    // Open location modal or redirect to location details/edit page
  };

  return (
    <DataGrid
      getRowHeight={(): any => "auto"}
      sx={dataGridServerStyles.sx}
      pageSizeOptions={[10, 25, 50, 100]}
      rows={rows}
      columns={columns}
      slots={{
        noRowsOverlay: TableNoDataOverlay,
      }}
      onRowClick={handleRowClick}
      columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
      autoHeight
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      rowCount={rows.length}
      sortingOrder={["desc", "asc"]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
          },
        },
      }}
    />
  );
};

export default LocationsTable;
