import { useLazyQuery } from "@apollo/client";

import { GET_SIGNED_URL_ACTION } from "../../common/operations/queries";

import {
  GetSignedUrlActionInput,
  GetSignedUrlActionQuery,
  GetSignedUrlActionQueryVariables,
} from "./../../API";

export const useGetSignedUrlAction = () => {
  const [getSignedUrlAction, { loading }] = useLazyQuery<
    GetSignedUrlActionQuery,
    GetSignedUrlActionQueryVariables
  >(GET_SIGNED_URL_ACTION);

  const fetchSignedUrlAction = async ({
    bucketKey,
    objectKey,
    contentType,
    operation,
  }: GetSignedUrlActionInput) => {
    const response = await getSignedUrlAction({
      variables: {
        input: {
          bucketKey,
          contentType,
          objectKey,
          operation,
        },
      },
    });

    const data = response?.data?.getSignedUrlAction ?? "{}";

    try {
      const parsedData = JSON.parse(data);

      if (parsedData?.error) {
        return "";
      }

      const body = parsedData?.body;

      if (body) {
        return parsedData?.body;
      }
    } catch (error) {
      return "";
    }
  };

  return {
    fetchSignedUrlAction,
    loading,
  };
};
