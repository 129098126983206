import { useState } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { DeviceData } from "../../../../../../API";
import CloseDialogIcon from "../../../../../../common/components/icons/CloseDialogIcon";
import ControlTable from "../../../../../../common/components/stream/ControlTable";
import { useGetNonAdminAccess } from "../../../../../../common/hooks/useGetNonAdminAccess";
import { usePTController } from "../../../../../../common/hooks/usePTController";
import { useStream } from "../../../../../../common/hooks/useStream";
import { RouteEnum } from "../../../../../../common/models/enums";
import { selectedStreamObjectVariable } from "../../../../../../common/variables/selectedStreamObject";
import { usePublishNode } from "../../../../../devices/hooks/usePublishNode";
import HLSStreamPlayer from "../cameraView/HLSStreamPlayer";
import RouterLink from "./../../../../../../common/components/RouterLink";
import PTZoneListTableContainer from "./ptzonelist/PTZoneListTableContainer";
import {
  selectedZoneVariable,
  useFirstZoneVariable,
  useSelectedZoneVariable,
} from "./ptzonelist/zones.variable";
import {
  PAN_DEFAULT,
  TILT_DEFAULT,
  ZOOM_DEFAULT,
} from "../../../../../../common/components/inputs/ptz/constants";

interface IDeviceImageListItemBarTitleProps {
  deviceName: string;
  deviceId: string;
  nodeId: string;
  locationName: string;
  deviceData: DeviceData;
}

const DeviceImageListItemBarTitle = ({
  deviceName,
  deviceId,
  nodeId,
  locationName,
  deviceData,
}: IDeviceImageListItemBarTitleProps): JSX.Element => {
  const { nonAdminView } = useGetNonAdminAccess();
  const selectedZone = useSelectedZoneVariable();
  const firstZoneVariable = useFirstZoneVariable();

  const { publishNode } = usePublishNode();
  const { getStreamName } = useStream();

  const [open, setOpen] = useState(false);
  const [ptOpen, setPtOpen] = useState(false);
  const [isReady, setIsReady] = useState(true);
  const [currentDevice, setCurrentDevice] = useState("");

  const {
    pan,
    tilt,
    zoom,
    disabled,
    movePtUp,
    movePtDown,
    movePtLeft,
    movePtRight,
    handleGoToPt,
    handlePanInputChange,
    handleTiltInputChange,
    handleZoomInputChange,
    validationState,
  } = usePTController({
    deviceId,
    deviceName,
    nodeId,
    deviceData: deviceData,
    zoneName: selectedZone.zoneName,
  });

  const handleLiveViewOnClick = (): void => {
    if (!isReady) return;

    // todo: come up with a better unique naming scheme
    setCurrentDevice(deviceId);

    const streamName = getStreamName(nodeId, deviceId);

    publishNode({
      message: JSON.stringify({
        TARGET: "KINESIS_STREAM",
        ACTION: "KINESIS_STREAM_START",
        streamName,
        deviceName,
      }),
      nodeId,
    }).then((): void => {
      selectedStreamObjectVariable({ streamName, deviceName, nodeId });

      setOpen(true);
    });
  };

  const handleOpenPtControlsOnClick = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "START_PT_CONTROL",
        device_name: deviceName,
      }),
      nodeId,
    }).then((): void => {
      setPtOpen(true);
    });
  };

  const handleClose = (): void => {
    const firstZonePan = firstZoneVariable?.pan ?? PAN_DEFAULT;
    const firstZoneTilt = firstZoneVariable?.tilt ?? TILT_DEFAULT;
    const firstZoneZoom = firstZoneVariable?.zoom ?? ZOOM_DEFAULT;
    const firstZoneName = firstZoneVariable?.zoneName ?? "";

    setOpen(false);

    setIsReady(false);

    setPtOpen(false);

    const streamName = getStreamName(nodeId, deviceId);

    publishNode({
      message: JSON.stringify({
        TARGET: "KINESIS_STREAM",
        ACTION: "KINESIS_STREAM_END",
        streamName,
        deviceName,
      }),
      nodeId,
    }).then((): void => {
      setIsReady(true);

      selectedStreamObjectVariable(null);
    });

    if (ptOpen) {
      handleGoToPt(firstZonePan, firstZoneTilt, firstZoneZoom, firstZoneName);

      publishNode({
        message: JSON.stringify({
          TARGET: "PANTILT",
          ACTION: "STOP_PT_CONTROL",
          device_name: deviceName,
        }),
        nodeId,
      }).then((): void => {
        selectedZoneVariable({
          zoneName: firstZoneName,
          pan: firstZonePan,
          tilt: firstZoneTilt,
          zoom: firstZoneZoom,
        });
      });
    }
  };

  const { hasZoom, hasPanTilt } = deviceData;

  return (
    <>
      <Dialog
        sx={{
          width: "100%",
        }}
        PaperProps={{
          sx: {
            maxHeight: "calc(100% - 174px)",
            height: "100%",
            maxWidth: "1360px",
          },
        }}
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
      >
        <CloseDialogIcon onClose={handleClose} />
        <DialogContent
          sx={{
            display: "flex",
            padding: "0px",
            width: "100%",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <HLSStreamPlayer setDialogStyles />
          <Box
            sx={{
              padding: "2em",
              paddingBottom: 0,
              width: "394px",
              maxWidth: "394px",
              minWidth: "394px",
            }}
          >
            {ptOpen ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5em",
                  }}
                >
                  <ErrorOutlineIcon sx={{ color: "warning.main" }} />
                  <Typography
                    sx={{ color: "warning.main", "& p": { margin: 0 } }}
                    variant="body2Regular"
                    component="span"
                  >
                    <p>All models are stopped while you</p>
                    <p>are running pt control</p>
                  </Typography>
                </Box>

                <Divider
                  sx={{
                    margin: "1.5em 0",
                  }}
                />

                <ControlTable
                  pan={pan}
                  tilt={tilt}
                  zoom={zoom}
                  ptDisabled={disabled}
                  validation={validationState}
                  showValidationAlert
                  handlePanInputChange={handlePanInputChange}
                  handleGoToPt={handleGoToPt}
                  handleTiltInputChange={handleTiltInputChange}
                  handleZoomInputChange={handleZoomInputChange}
                  movePtLeft={movePtLeft}
                  movePtRight={movePtRight}
                  movePtUp={movePtUp}
                  movePtDown={movePtDown}
                  hasZoom={hasZoom}
                />

                <Divider
                  sx={{
                    margin: "1.5em 0",
                  }}
                />

                <Typography variant="body1Bold" component="span">
                  Observing zones
                </Typography>

                <PTZoneListTableContainer
                  currentDeviceId={currentDevice}
                  nodeId={nodeId}
                  hasZoom={hasZoom}
                  disableRowClick={disabled}
                  handleGoToPt={handleGoToPt}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{ border: 0, width: "80px" }}
                      >
                        <Typography variant="inputLabel" component="span">
                          DEVICE
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: 0 }}>
                        <Typography variant="body2Bold" component="span">
                          {deviceName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        variant="head"
                        sx={{ border: 0, width: "80px" }}
                      >
                        <Typography variant="inputLabel" component="span">
                          LOCATION
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ border: 0 }}>
                        <Typography variant="body2Regular" component="span">
                          {locationName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box
                  sx={{
                    marginTop: "auto",
                  }}
                >
                  {hasPanTilt && (
                    <>
                      <Button
                        sx={{
                          display: ptOpen ? "none" : "block",
                          color: "text.primary",
                          borderColor: "text.primary",
                          marginBottom: "1em",
                        }}
                        variant="outlined"
                        size="small"
                        onClick={handleOpenPtControlsOnClick}
                      >
                        <Typography variant="buttonMedium" component="span">
                          Open PT Controller
                        </Typography>
                      </Button>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5em",
                          paddingBottom: "2em",
                        }}
                      >
                        <ErrorOutlineIcon sx={{ color: "warning.main" }} />
                        <Typography
                          sx={{ color: "warning.main", "& p": { margin: 0 } }}
                          variant="body2Regular"
                          component="span"
                        >
                          <p>
                            All models are stopped while you are running pt
                            control
                          </p>
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "1em 0.5em 0 0.5em",
        }}
      >
        <Typography
          variant="body1Bold"
          sx={{
            "&:hover": {
              cursor: isReady ? "pointer" : "not-allowed",
              color: (theme): string => theme.palette.primary.main,
            },
          }}
          onClick={handleLiveViewOnClick}
        >
          {deviceName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: hasPanTilt ? "baseline" : "center",
          gap: "0.5em",
          margin: "1em 0.5em",
        }}
      >
        <Typography variant="inputLabel" component="span">
          Location
        </Typography>
        <Typography variant="body2Regular" component="span">
          {locationName}
        </Typography>
        <Button
          variant="text"
          sx={{
            marginLeft: "auto",
            padding: "2px 4px",
            height: "26px",
          }}
        >
          {hasPanTilt && (
            <RouterLink
              title="Zones List"
              to={
                nonAdminView
                  ? `${
                      RouteEnum.SensoryLiveViewZoneListShort
                    }/${encodeURIComponent(deviceId)}`
                  : `${RouteEnum.ZoneListShort}/${encodeURIComponent(deviceId)}`
              }
              state={{
                deviceId,
              }}
            />
          )}
        </Button>
      </Box>
    </>
  );
};

export default DeviceImageListItemBarTitle;
