import { useCallback } from "react";

import { DataGrid, GridRowModel } from "@mui/x-data-grid";
import isEqual from "lodash/isEqual";

import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { dataGridServerStyles } from "../../../../../common/providers/theme/design-tokens/DataGrid/dataGridServer.styles";
import { DEFAULT_COLUMN_HEADER_HEIGHT } from "../../../../../common/variables/common";
import { errorNotification } from "../../../../../common/variables/notification";
import { useDeleteDeviceMake } from "../hooks/useDeleteDeviceMake";
import { useDevicesMakesTableColumns } from "../hooks/useDevicesMakesTableColumns";
import { useDevicesMakesTableRows } from "../hooks/useDevicesMakesTableRows";
import { DEVICES_MAKES_TABLE_LIMIT } from "../hooks/useGetLazyPaginatedDevicesMakes";
import { useUpdateDeviceMake } from "../hooks/useUpdateDeviceMake";

const DevicesMakesTable = (): JSX.Element => {
  const { rows, loading } = useDevicesMakesTableRows();
  const { columns, rowModesModel, setRowModesModelChange } =
    useDevicesMakesTableColumns();
  const { updateDeviceMake, loading: updateDeviceMakeLoading } =
    useUpdateDeviceMake();
  const { loading: deleteDeviceMakeLoading } = useDeleteDeviceMake();

  const processRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel
    ): Promise<GridRowModel> => {
      if (isEqual(newRow, oldRow)) {
        return oldRow;
      }

      const input = {
        id: rows.find((row): boolean => row.model === newRow.model)
          ?.rowId as string,
        name: newRow.name as string,
        model: newRow.model as string,
        rtspTemplate: newRow.rtspTemplate as string,
        type: newRow.type as string,
        hasPanTilt: newRow.hasPanTilt as boolean,
        modelsToRun: newRow.modelsToRun as string[],
      };

      await updateDeviceMake(input);

      return newRow;
    },
    [updateDeviceMake]
  );

  const handleProcessRowUpdateError = (): void => {
    errorNotification();
  };

  return (
    <DataGrid
      sx={{
        ...dataGridServerStyles.sx,
        "& .MuiDataGrid-row--editing": {
          boxShadow:
            "rgba(0, 0, 0, 0.2) -1px -1px 6px 2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
        },
      }}
      pageSizeOptions={[DEVICES_MAKES_TABLE_LIMIT]}
      rows={rows}
      columns={columns}
      slots={{
        noRowsOverlay: TableNoDataOverlay,
      }}
      columnHeaderHeight={DEFAULT_COLUMN_HEADER_HEIGHT}
      autoHeight
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId?: string }): string => row?.rowId ?? ""}
      loading={loading || updateDeviceMakeLoading || deleteDeviceMakeLoading}
      rowCount={rows.length}
      sortingOrder={["desc", "asc"]}
      editMode="row"
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      rowModesModel={rowModesModel}
      onRowModesModelChange={setRowModesModelChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: DEVICES_MAKES_TABLE_LIMIT,
          },
        },
      }}
    />
  );
};

export default DevicesMakesTable;
