import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import type { GraphQLSubscription } from "@aws-amplify/api";
import { CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { API, Hub, graphqlOperation } from "aws-amplify";

import { ListenToHumanValidationEventsSubscription } from "../../../API";
import TransitionUp from "../../../common/components/TransitionUp";
import Thumbnail from "../../../common/components/media/Thumbnail";
import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";
import { useToggle } from "../../../common/hooks/useToggle";
import { listenToHumanValidationEvents } from "../../../graphql/subscriptions";
import { hvConnection } from "../../human-validator/variables/humanValidatorTable";
import { AUDIT_TABLE_LIMIT } from "../hooks/useGetHumanValidatedEventsForAuditing";
import { useHvReviewTableColumns } from "../hooks/useHvReviewTableColumns";
import { useHvReviewTableRows } from "../hooks/useHvReviewTableRows";

const badConnectionStates = [
  ConnectionState.ConnectedPendingDisconnect,
  ConnectionState.Disconnected,
];

// todo: HV Review table does not work because of an undefined mediaUrl to thumbnail
const HvReviewTable = (): JSX.Element => {
  const { rows, loading } = useHvReviewTableRows();
  const { columns } = useHvReviewTableColumns();

  const { state, setToggleState } = useToggle({ initialState: false });

  const [mediaOutputUrl, setMediaOutputUrl] = useState();

  const connected = useReactiveVar(hvConnection);

  useEffect((): (() => void) => {
    Hub.listen("api", (data): void => {
      const { payload } = data;

      if (payload.event === CONNECTION_STATE_CHANGE) {
        if (badConnectionStates.includes(payload.data.connectionState)) {
          hvConnection(false);
        } else {
          hvConnection(true);
        }
      }
    });

    const sub = API.graphql<
      GraphQLSubscription<ListenToHumanValidationEventsSubscription>
    >(graphqlOperation(listenToHumanValidationEvents)).subscribe({
      next: (data): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents next",
          data
        ),
      error: (error): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents error",
          error
        ),
      complete: (): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents completed",
          "Completed!"
        ),
    });

    return (): void => {
      if (!connected) {
        sub.unsubscribe();
      }
    };
  }, []);

  const handleRowClick = (params: GridRowParams): void => {
    setToggleState(true);

    setMediaOutputUrl(params.row.media);
  };

  const handleModalClose = (): void => {
    setToggleState(false);
  };

  return (
    <>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
        }}
        pageSizeOptions={[AUDIT_TABLE_LIMIT]}
        rows={rows}
        getRowClassName={(): string => "cursor-pointer"}
        onRowClick={handleRowClick}
        columns={columns}
        slots={{
          noRowsOverlay: TableNoDataOverlay,
        }}
        autoHeight
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
        getRowId={(row: { rowId?: string }): string => row?.rowId ?? ""}
        loading={loading}
        rowCount={rows.length}
        sortingOrder={["desc", "asc"]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: AUDIT_TABLE_LIMIT,
            },
          },
        }}
      />
      <Dialog
        fullScreen
        open={state}
        onClose={handleModalClose}
        TransitionComponent={TransitionUp}
      >
        <DialogTitle>
          <CardHeader
            sx={{ padding: 0 }}
            action={
              <IconButton onClick={(): void => setToggleState(false)}>
                <CloseRoundedIcon />
              </IconButton>
            }
            title="Details"
          />
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Thumbnail mediaOutput={mediaOutputUrl ?? ""} controls />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HvReviewTable;
