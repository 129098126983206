import { useMemo } from "react";

import { GridRowsProp } from "@mui/x-data-grid";
import uniqBy from "lodash/uniqBy";
import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import { getRowIndex } from "../../common/events.helpers";
import {
  IGateGuardEventsTableRow,
  IParsedGateGuardEvent,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";

export const useGetGateGuardEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<IGateGuardEventsTableRow> => {
    const mappedData: IGateGuardEventsTableRow[] = parsedResult?.map(
      (item: IParsedGateGuardEvent, index: number) => ({
        rowIndex: getRowIndex(paginationModel, index),
        rowId: item?.id ?? "",
        location: item?.location_id,
        timestamp: item?.time
          ? moment.utc(item.time).local().format(EVENTS_DATE_FORMAT)
          : "",
        keyFrame: item?.keyFrame,
        mediaOutput: item?.mediaOutput,
        signedUrlKeyFrame: item?.signedUrlKeyFrame,
        signedUrlMediaOutput: item?.signedUrlMediaOutput,
        licensePlate: item?.license_plate,
        confidence: item?.confidence,
        driver_name: item?.driver_name,
        company_name: item?.company_name,
        category_name: item?.category_name ?? "Unknown",
        cameraId: item?.camera_id,
      })
    );

    const uniqFilteredData = uniqBy(mappedData, item => item.rowId);

    return uniqFilteredData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
