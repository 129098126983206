import { FC, useEffect, useState } from "react";

import { Box, CardMedia } from "@mui/material";

import { signedUrlActions } from "../../../API";
import FullPageLoader from "../../../common/components/item/FullPageLoader";
import { S3BucketKeys } from "../../../common/utils/s3helper";
import { useGetSignedUrlAction } from "../../../leak-finder/hooks/useGetSignedUrlAction";
import NoMedia from "./errors/NoMedia";

interface ImageViewerProps {
  s3Key: string;
}

const ImageViewer: FC<ImageViewerProps> = ({ s3Key }) => {
  const [imageUrl, setImageUrl] = useState("");

  const [error, setError] = useState(false);

  const { fetchSignedUrlAction, loading } = useGetSignedUrlAction();

  useEffect((): void => {
    getImage();
  }, [s3Key]);

  const getImage = async (): Promise<void> => {
    if (!s3Key) {
      setImageUrl("");

      setError(true);

      return;
    }

    const signedUrl = await fetchSignedUrlAction({
      objectKey: s3Key,
      operation: signedUrlActions.getObject,
      bucketKey: S3BucketKeys.NODE_FILES,
    });

    if (!signedUrl) {
      setImageUrl("");

      setError(true);

      return;
    }

    setImageUrl(signedUrl);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          maxHeight: "100vh",
        }}
      >
        <FullPageLoader />
      </Box>
    );
  }

  if (error) {
    return <NoMedia />;
  }

  return (
    <CardMedia
      sx={{
        height: "100%",
        maxHeight: "100vh",
      }}
      src={imageUrl}
      component="img"
      onError={() => setError(true)}
    />
  );
};

export default ImageViewer;
