import { useMemo } from "react";

import { GridRowsProp } from "@mui/x-data-grid";
import uniqueId from "lodash/uniqueId";
import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import {
  IParsedRecentEvent,
  IRecentEventsTableRow,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";
import { getRowIndex } from "../../common/events.helpers";

export const DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE = 10;

export const DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export interface IEventsTableRow {
  rowId: string;
  location: string;
  timestamp: string;
  keyFrame: string;
}

export const useGetRecentEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer(DEFAULT_RECENT_ACTIVITIES_PAGE_SIZE);

  const rows = useMemo((): GridRowsProp<IRecentEventsTableRow> => {
    const parsedData: IRecentEventsTableRow[] = parsedResult?.map(
      (item: IParsedRecentEvent, index: number) => {
        const rowIndex = getRowIndex(paginationModel, index);

        return {
          rowIndex,
          rowId: item?.id ?? uniqueId(),
          location: item?.location_id,
          timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
          keyFrame: item?.keyFrame,
          mediaOutput: item?.mediaOutput,
          signedUrlKeyFrame: item?.signedUrlKeyFrame,
          signedUrlMediaOutput: item?.signedUrlMediaOutput,
          model: item?.model,
          zoneName: item?.zone_id,
          cameraId: item?.camera_id,
        };
      }
    );

    return parsedData;
  }, [parsedResult]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
