import { memo, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import { SxProps, Theme } from "@mui/material/styles";

import { IS3MediaFile } from "./IS3MediaFileProps";
import KeyFrameMessage from "./NoKeyFrameMessage";

interface S3ImageProps extends IS3MediaFile {
  isKeyframeLoaded?: boolean;
  width?: number;
  deviceStatus?: boolean;
  height?: number;
  sx?: SxProps<Theme>;
  setKeyframeLoaded?: (value: boolean) => void;
  onClick?: () => void;
}

const S3ImageComponent = ({
  s3Key,
  width,
  height,
  sx = [],
  isKeyframeLoaded,
  deviceStatus,
  setKeyframeLoaded,
  onClick,
}: S3ImageProps): JSX.Element => {
  const [imageUrl, setImageUrl] = useState("");
  const [doesFileExist, setDoesFileExist] = useState(false);

  const defaultSx = {
    objectFit: "unset",
    aspectRatio: `${width && height ? `auto ${width}/${height}` : "auto"}`,
    width: width ? width + "px" : "fit-content",
    height: height ? height + "px" : "100%",
  };

  useEffect((): void => {
    getImage();
  }, [s3Key]);

  const getImage = async (): Promise<void> => {
    if (!s3Key) {
      setImageUrl("");

      setDoesFileExist(false);

      if (setKeyframeLoaded) {
        setKeyframeLoaded(false);
      }

      return;
    }

    setImageUrl(s3Key);

    setDoesFileExist(true);

    if (setKeyframeLoaded) {
      // checkIfFileExist has finished and therefore loading is complete
      setKeyframeLoaded(true);
    }
  };

  /**
   * If the image fails to load, show 'Please wait for the keyframe to retrieve' message
   */
  const handleImageError = async () => {
    setImageUrl("");

    setDoesFileExist(false);

    if (setKeyframeLoaded) {
      setKeyframeLoaded(false);
    }
  };

  return (
    <Box
      sx={{
        width: width ?? "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      {!isKeyframeLoaded && (
        <>
          {(!doesFileExist || (!imageUrl && deviceStatus === undefined)) && (
            <KeyFrameMessage message="Please wait for the keyframe to retrieve" />
          )}
          {deviceStatus === false && !imageUrl && (
            <KeyFrameMessage message="The device is offline. It can take some time" />
          )}
        </>
      )}

      {imageUrl && doesFileExist && (
        <CardMedia
          onClick={onClick}
          component="img"
          src={imageUrl}
          height={height}
          sx={[defaultSx, ...(Array.isArray(sx) ? sx : [sx])]}
          onError={handleImageError}
        />
      )}
    </Box>
  );
};

const S3Image = memo(
  S3ImageComponent,
  (prevProps, nextProps): boolean => prevProps.s3Key === nextProps.s3Key
);

export default S3Image;
