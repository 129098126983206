import { useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { AddServiceInput } from "../../../API";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useCreateService } from "../hooks/useCreateService";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import NodeSelect from "../../../common/components/select/NodeSelect/NodeSelect";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import DynamicFields from "./DynamicFields";

const CreateServicePage = (): JSX.Element => {
  const { createService, loading } = useCreateService();
  const selectedCustomerId = useCustomerIdGuard();

  const [locationId, setLocationId] = useState<AutocompleteOptionType>(null);
  const [nodeId, setNodeId] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("Gas Leak");
  const [serviceConfig, setServiceConfig] = useState<any>({});

  const [dockerImage, setDockerImage] = useState(
    "534256877503.dkr.ecr.us-east-1.amazonaws.com/ccai-gasleak:latest"
  );
  const [mlModelPath, setMlModelpath] = useState("./trained_model/42525.pt");
  const [minLeakLength, setMinLeakLength] = useState("20");
  const [videoDuration, setVideoDruation] = useState("120");
  const [displayRate, setDisplayRate] = useState("True");
  const [calcFlowRate, setCalcFlowRate] = useState("True");

  const saveService = (): void => {
    const input: AddServiceInput = {
      nodeId,
      deviceId: "",
      locationId: locationId?.value ?? "",
      customerId: selectedCustomerId ?? "",
      configuration: JSON.stringify({
        gpu_id: 0,
        minimum_leak_length: minLeakLength,
        video_duration: videoDuration,
        display_rate: displayRate,
        calc_flow_rate: calcFlowRate,
        docker_image: dockerImage,
      }),
      serviceType: selectedServiceType,
    };

    createService(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      });
  };

  return (
    <>
      <Paper sx={{ padding: "1em" }} elevation={3}>
        <Typography variant="h5" padding={0}>
          Create a new Service
        </Typography>
        <Box>
          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
          >
            {/* TODO for Denys: implement container/presentation pattern */}
            <Grid item>
              <FormControl fullWidth sx={{ marginTop: "2em" }}>
                <InputLabel>Service Type</InputLabel>
                <Select
                  fullWidth
                  label="Service Type"
                  value={selectedServiceType}
                  onChange={(e): void => {
                    setSelectedServiceType(e.target.value);
                  }}
                >
                  <MenuItem value={"Gas Leak"}>Gas Leak Detection</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <LocationIdSelect
                location={locationId}
                setLocation={setLocationId}
              />
            </Grid>
            <Grid item>
              <NodeSelect nodeId={nodeId} onNodeChange={setNodeId} />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="docker-image"
                label="Docker Image"
                value={dockerImage}
                onChange={(e): void => setDockerImage(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="ml_model_path"
                label="ML Model Path"
                value={mlModelPath}
                onChange={(e): void => setMlModelpath(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="min_leak_length"
                label="Min Leak Length"
                value={minLeakLength}
                onChange={(e): void => setMinLeakLength(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="vid_duration"
                label="Video Duration"
                value={videoDuration}
                onChange={(e): void => setVideoDruation(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="calc_flow_rate"
                label="Calc Flow Rate"
                value={calcFlowRate}
                onChange={(e): void => setCalcFlowRate(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                required
                fullWidth
                disabled
                id="display_rate"
                label="Display Rate"
                value={displayRate}
                onChange={(e): void => setDisplayRate(e.target.value)}
              />
            </Grid>
            <br />
            <Divider />
            <Grid item>
              <DynamicFields
                serviceConfig={serviceConfig}
                setServiceConfig={setServiceConfig}
                selectedService={selectedServiceType}
              />
            </Grid>
            <Grid item>
              <StyledLoadingButton
                fullWidth
                loading={loading}
                loadingPosition="start"
                startIcon={<CheckIcon />}
                variant="contained"
                color="success"
                onClick={saveService}
              >
                Confirm
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default CreateServicePage;
