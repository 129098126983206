import { useMemo } from "react";

import { GridRowsProp } from "@mui/x-data-grid";
import moment from "moment";

import { EVENTS_DATE_FORMAT } from "../../common/events.constants";
import { getRowIndex } from "../../common/events.helpers";
import {
  IHardHatEventsTableRow,
  IParsedHardHatEvent,
} from "../../common/events.models";
import { useGetTimestreamDataServer } from "../../common/hooks/useGetTimestreamDataServer";

export const useGetHardHatEventsTableRows = () => {
  const {
    parsedResult,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  } = useGetTimestreamDataServer();

  const rows = useMemo((): GridRowsProp<IHardHatEventsTableRow> => {
    const mappedData: IHardHatEventsTableRow[] =
      parsedResult?.map((item: IParsedHardHatEvent, index: number) => {
        return {
          rowIndex: getRowIndex(paginationModel, index),
          rowId: item?.id ?? "",
          location: item?.location_id,
          timestamp: moment.utc(item?.time).local().format(EVENTS_DATE_FORMAT),
          keyFrame: item?.keyFrame,
          mediaOutput: item?.mediaOutput,
          signedUrlKeyFrame: item?.signedUrlKeyFrame,
          signedUrlMediaOutput: item?.signedUrlMediaOutput,
          cameraId: item?.camera_id,
          confidence: item?.confidence,
        };
      }) ?? [];

    return mappedData;
  }, [parsedResult, paginationModel]);

  return {
    rows,
    loading,
    paginationModel,
    handlePaginationModelChange,
    hasNextPage,
    isActivePageChange,
  };
};
