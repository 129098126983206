import { useCallback, useState } from "react";

import axios from "axios";

import { signedUrlActions } from "../../API";
import { S3BucketKeys } from "../../common/utils/s3helper";
import { useGetSignedUrlAction } from "./useGetSignedUrlAction";

const useDeleteS3File = () => {
  const { fetchSignedUrlAction } = useGetSignedUrlAction();

  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteFile = useCallback(async (uploadLink: string) => {
    setIsLoading(true);

    try {
      const signedURLPathToDelete = await fetchSignedUrlAction({
        bucketKey: S3BucketKeys.LEAK_FINDER,
        objectKey: uploadLink,
        operation: signedUrlActions.deleteObject,
      });

      await axios.delete(signedURLPathToDelete);

      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Unknown error occurred")
      );

      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { deleteFile, error, loading: isLoading };
};

export default useDeleteS3File;
