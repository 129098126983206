import { useEffect, useMemo, useRef, useState } from "react";

import { KONVA_HEIGHT, KONVA_WIDTH } from "./utils";

const useCreateImageLayer = (
  keyFrame: string,
  setKeyframeLoaded?: (state: boolean) => void,
  view = false
) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<HTMLImageElement>();
  const [imageSource, setImageSource] = useState("");
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [skale, setSkale] = useState({ scaleX: 1, scaleY: 1 });
  const [skaleFactor, setSkaleFactor] = useState(1);

  const imageRef = useRef<any>(null);

  const { scaleX, scaleY } = skale;

  const setImagePath = () => {
    setLoading(true);

    if (keyFrame) {
      if (setKeyframeLoaded && keyFrame) setKeyframeLoaded(true);

      setImageSource(keyFrame);
    } else {
      setImageSource("");

      if (setKeyframeLoaded) setKeyframeLoaded(false);
    }

    setLoading(false);
  };

  const handleRetry = () => setImagePath();

  useEffect(() => setImagePath(), []);

  const imageElement = useMemo(() => {
    const element = new window.Image();

    element.src = imageSource;

    return element;
  }, [imageSource]);

  useEffect(() => {
    const onload = function () {
      setLoading(true);

      setSize({
        width: imageElement.width,
        height: imageElement.height,
      });

      const skaleFactor = imageElement.width / imageElement.height;

      let scaleX = imageElement.width / (KONVA_HEIGHT * skaleFactor);
      let scaleY = imageElement.height / KONVA_HEIGHT;

      if (view) {
        scaleX = imageElement.width / KONVA_WIDTH;

        scaleY = imageElement.height / KONVA_WIDTH / skaleFactor;
      }

      setSkale({ scaleX, scaleY });

      setSkaleFactor(skaleFactor);

      setImage(imageElement);

      imageRef.current = imageElement;

      setLoading(false);
    };

    imageElement.addEventListener("load", onload);

    return () => {
      imageElement.removeEventListener("load", onload);
    };
  }, [imageElement]);

  return {
    image,
    size,
    imageRef,
    scaleX,
    scaleY,
    skaleFactor,
    loading,
    handleRetry,
  };
};

export default useCreateImageLayer;
